<template>
<div>
  <div class="row">

    <div class="col-sm-12">
      <div class="form-group required">
          <label for="input-middlename" class="control-label">Business Type</label>
          <!-- <input type="text" class="form-control" placeholder="Business Type" v-model="basicInfo.business_type"> -->
          <select class="form-control select-arrow"  @change="changeBusinessType($event.target.value)" v-model="basicInfo.business_type">
            <option value=""> --- Please Select --- </option>
            <option v-for="(item, val) in businessTypes" :key="val" :value="item.value">{{ item.name}}</option>
          </select>
      </div>
      <div class="form-group required">
          <label for="input-middlename" class="control-label">Business Display Name</label>
          <input type="text" class="form-control" placeholder="Business Display Name" v-model="basicInfo.business_name">
      </div>
      <div class="form-group required">
          <label for="input-middlename" class="control-label">Business Registration Number</label>
          <input type="text" class="form-control" placeholder="Business Registration Number" v-model="basicInfo.business_reg_no">
      </div>
    </div>

    <div class="col-sm-12">
      <hr/>
     <p class="lead">Business Registration Certification</p>
     <div>
      <p style="font-size: 16px; color: red;">* Please upload your images</p>
      <div style="margin-left: 10px;">
        <p style="color: red">* The photo of the ID/Document should be in high quality.</p>
        <p style="color: red;">* All four corners to be visible.</p>
        <p style="color: red">* We accept .Jpg / .PNG & PDF formats only, Must be below 3MB.</p>
      </div>
     </div>
      <div class="row">
        <div class="col-sm-12">
            <h5><b>{{ Object.keys(fullInfo).length != 0 ? fullInfo.kyc_lists[0].certificate_approve_status :" " }}</b></h5>
            <h5 v-if="showRejectReason"><b>{{ Object.keys(fullInfo).length != 0 ? 'Reject reason: -' + fullInfo.kyc_lists[0].certificate_reject_reason.reason :" " }}</b></h5>
            <div v-if="showRejectReason && fullInfo.kyc_lists"><div v-html="fullInfo.kyc_lists[0].certificate_reject_reason_description !== undefined ? fullInfo.kyc_lists[0].certificate_reject_reason_description :''"></div>
            </div>
            <img v-if="!isPdf" style="width: auto; height: 300px;" slot="image" class="card-img-top" :src="contactImage1" alt="Card image cap">
            <iframe
              v-if="isPdf"
              :src="contactImage1"
              frameBorder="0"
              scrolling="none"
              height="325px"
              width="100%"
            ></iframe>
            <br>
            <br>
            <button class="btn btn-sm merchprof-sbtn" v-if="showComponent" @click="pickKycProf()">Upload</button>
            <input 
              type="file" 
              class="inputfile"
              @change="getFile1"
              id="embedpollfileinput"
              ref="upprofpicturemer1"
              style="display: none;">

        </div>
      </div>
    </div>

  </div>

   <div v-if="showComponent" class="row">
      <div class="col-sm-12" style="margin-top: 30px;">
          <div class="form-group text-right" >
              <button v-if="!isLoading" class="btn btn-primary" :disabled="!validate" @click="putFormData()">Submit</button>
          </div>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-sm-12 footerClass2">
        <div class="text-right">
          <div style="display: flex; width: 140px; justify-content: end; flex-direction: column; align-items: unset; float: right;">
            <button
              disabled
              style="color: white;"
              class="btn merchprof-sbtn"
            >Loading...
            </button>
            <progress-bar
              style="margin-top: 10px;"
              :options="options"
              :value="loadingprogress"
            />
          </div>
        </div>
      </div>
    </div>

</div>  
</template>
<script>
import mixinProfile from "../../../mixins/merchant/APIProfile";
import mixinStore from "@/mixins/merchant/APIStore";
import { async } from 'q';
export default {
  mixins: [mixinProfile, mixinStore],
  props:{
    switchToBusiness: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      loadingprogress: 0,
      basicInfo: {
        business_type: "",
        business_name: "",
        business_reg_no: ""
      },
      businessTypes:[
        {
          name:"Sole Proprietorship",
          value:"Sole Proprietorship"
        },
        {
          name:"Partnership",
          value:"Partnership"
        },
        {
          name:"Corporation",
          value:"Corporation"
        },
        {
          name:"Limited Liability Company",
          value:"Limited Liability Company"
        }
      ],
      fileUploadFormData: new FormData(),
      image1: "",
      img1: "",
      isPdf: false,
      fullInfo: {},
      kycInfo:[],
      mKycId: "",
      isLoading: false,
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#C0C0C0'
        },
        layout: {
          height: 30,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }
    }
   },
   computed: {
    validate: function() {
      return (
        this.basicInfo.business_type &&
        this.basicInfo.business_name &&
        this.basicInfo.business_reg_no &&
        this.image1
      );
    },
    contactImage1: function() {
      if (this.image1) {
        // return this.image1;
        return this.image1;

      } else {
        return "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629964061/cochchi/mercahnt/1629964060169.webp";
      }
    },
    showComponent: function() {
      if (Object.keys(this.fullInfo).length != 0) {
        if (this.fullInfo.kyc_lists[0].certificate_approve_status !== "approved") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    showRejectReason: function() {
      if (Object.keys(this.fullInfo).length != 0) {
        if (this.fullInfo.kyc_lists[0].certificate_approve_status === "rejected") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
   },
   created() {
    this.getPersanalInfo();
   },
   methods: {
    pickKycProf: function() {
      this.$refs.upprofpicturemer1.click();
    },
    changeBusinessType: function(event) {
      this.basicInfo.business_type = event;
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.$parent.checkRequiredStage();
        if (response.kyc_lists.length === 0) {
          this.mKycId = "";
        } else {
          this.mKycId = response.kyc_lists[0]._id;
          this.image1 = response.kyc_lists[0].business_reg_certificate;
          if (this.image1.split('.').pop().toLowerCase() === "pdf") {
            this.isPdf = true;
          } 
          else {
            this.isPdf = false;
          }
          this.basicInfo.business_type = response.kyc_lists[0].business_type;
          this.basicInfo.business_name = response.kyc_lists[0].business_name;
          this.basicInfo.business_reg_no = response.kyc_lists[0].business_reg_no;
          this.fullInfo = response;
          this.kycInfo = response.kyc_lists[0]
          this.$emit("kycComplete", this.kycInfo);
        }
      } catch (error) {
        console.log("error");
      }
    },
    getFile1: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf = true;
      } 
      else {
        this.isPdf = false;
      }
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image1 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image1 = tgt.files[0];
      this.img1 = eventf.target.files[0]
      // if(this.fullInfo.kyc_lists[0].certificate_approve_status === "approved" || this.fullInfo.kyc_lists[0].certificate_approve_status === "rejected" ){
      //   this.fullInfo.kyc_lists[0].certificate_approve_status = "resubmit";
      // }
    },
    putFormData: async function() {
      this.loadingprogress = 17;
      this.isLoading = true;
      let certificateKycType = 5;
      await this.fileUploadFormData.append("kyc_type", certificateKycType);
      await this.fileUploadFormData.set("business_type", this.basicInfo.business_type);
      await this.fileUploadFormData.set("business_name", this.basicInfo.business_name);
      await this.fileUploadFormData.append("business_reg_no", this.basicInfo.business_reg_no);
      if (this.img1 !== "") {
        await this.fileUploadFormData.append("image", this.img1);
      } else {
        await this.fileUploadFormData.set("image", this.image1);
      }

      let formData = await this.fileUploadFormData;
      this.loadingprogress = 36;
     try {
      await this.putCertificateImgUpdate(formData);
      this.loadingprogress = 100;
      this.isLoading = false;
      if(!this.fullInfo.kyc_lists){
        if(this.switchToBusiness != 1){
          this.checkStage()
          await this.$parent.postRequiredStage(5);
        }
      }

      if(this.switchToBusiness == 1){
        this.$store.dispatch("logoutMerchant");
        this.$router.push('/merchant-landing-new').catch(err => {});
        this.$swal.fire({
            position: 'center',
            type: 'warning',
            title: 'Please Login Again',
            showConfirmButton: false,
            timer: 1500
        }).then(function() {              
        }) 
        return;
      }
      this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'Submit success',
          showConfirmButton: false,
          timer: 1500
      });
      this.fileUploadFormData = new FormData();
      this.getPersanalInfo();
      //  if (this.mKycId === "") {
      //     await this.putCertificateImgCreate(formData);
      //   } else {
          
      //   }

        // let self = this;
        // this.$swal.fire({
        //     position: 'center',
        //     type: 'success',
        //     title: 'Submit success',
        //     showConfirmButton: false,
        //     timer: 1500
        // }).then(function() {                  
        //   // self.$parent.activateTab(5);
        // }) 
      // this.$emit("certificate_approve_status", this.fullInfo.kyc_lists[0].certificate_approve_status)
     } catch (error) {
        this.isLoading = false;
        this.fileUploadFormData = new FormData();
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
     }
    },
    checkStage: async function() {
      try {
        let response = await this.getStores();
        if (response.length === 0) {
          await this.$parent.postRequiredStage(5);
          // await this.$parent.activateTab(5);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style scoped>
.merchprof-sbtn{
  background: #5779ae;
  color: #000000;
  border: none;
}
.merchprof-sbtn:hover{
  color: #ffffff;
  background: #5779ae;
}
</style>