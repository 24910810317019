<template>
  <div>
    <ul class="nav nav-tabs">

      <li v-on:click="activetab = 1" :class="activetab === 1 ? 'active' : ''">
        <a data-toggle="tab" href="#personal">Personal</a>
      </li>
      <li v-on:click="activetab = 2" :class="activetab === 2 ? 'active' : ''">
        <a data-toggle="tab" href="#address">Addresses</a>
      </li>
      <li v-on:click="activetab=3" :class=" activetab === 3 ? 'active':''" id="kycBtn"><a data-toggle="tab" href="#kyc">KYC</a></li>
      <li v-on:click="activetab=4" :class=" activetab === 4 ? 'active' : '' "><a data-toggle="tab" href="#bank">Bank Account Details</a></li>
      <li v-on:click="activetab = 5" :class="activetab === 5 ? 'active' : ''">
        <a data-toggle="tab" href="#password">Change Password</a>
      </li>
      <li v-on:click="activetab=6" :class=" activetab === 6 ? 'active ' : '' " id="BusinessBtn" v-show="info.account_type === 2"><a :class=" activetab === 3 ? 'removeBg ' : '' " data-toggle="tab" href="#businessReg" @click="completeKyc1">Business Reg</a></li>
      <li v-on:click="activetab=8" :class=" activetab === 8 ? 'active' : '' "><a data-toggle="tab" href="#entitlements">Entitlements</a></li>
      <li v-on:click="activetab=9" :class=" activetab === 9 ? 'active' : '' "><a data-toggle="tab" href="#ownDelivary">Delivery</a></li>
    </ul>
    <div class="tab-content scopedClass">
      <div v-if="activetab === 1" id="personal" class="tab">
        <ProfilePersonal />
      </div>
      <div v-if="activetab === 2" id="address" class="tab">
        <ProfileAddress />
      </div>
      <div v-if="activetab === 3" id="kyc" class="tab">
        <ProfileKyc />
      </div>
      <div v-if="activetab === 4" id="bank" class="tab">
        <ProfileBankAccount />
      </div>
      <div v-if="activetab === 5" id="password" class="tab">
        <ProfilePassword />
      </div>
      <div v-if="activetab === 6" :style=" info.account_type === 2 ? 'display: bolck' : 'display: none'" id="businessReg" class="tab" >
        <ProfileBusinessReg v-bind:switchToBusiness="switchToBusiness" @kycComplete="kycComplete"/>
      </div>
      <div v-if="activetab === 8" id="entitlements" class="tab">
        <Entitlements />
      </div>
      <div v-if="activetab === 9" id="ownDelivary" class="tab">
        <OwnDelivary />
      </div>
    </div>
  </div>
</template>
<script>
import OwnDelivary from "@/components/Merchant/profile/OwnDelivary";
import Entitlements from "@/components/Merchant/profile/Entitlements";
import ProfilePersonal from "@/components/Merchant/profile/ProfilePersonal";
import ProfileAddress from "@/components/Merchant/profile/ProfileAddress";
import ProfilePassword from "@/components/Merchant/profile/ProfilePassword";
import ProfileBankAccount from "@/components/Merchant/profile/ProfileBankAccount";
import ProfileKyc from "@/components/Merchant/profile/ProfileKyc";
import ProfileBusinessReg from "@/components/Merchant/profile/ProfileBusinessReg";
import { async } from "q";
import mixinProfile from "../../../mixins/merchant/APIProfile";

export default {
  // name: "Myaccount",
  mixins: [mixinProfile],
  data() {
    return {
      breadcrumb: [
        {
          text: "Merchant Profile",
          active: false,
        },
      ],
      electedContactType: "",
      info: {},
      kycInfo: [],
      activetab: 1,
      requiredStage: null,
      account_type: 0,
      currentstage: 1,
      switchToBusiness: 0,
    };
  },
  components: {
    OwnDelivary,
    Entitlements,
    ProfilePersonal,
    ProfileAddress,
    ProfilePassword,
    ProfileKyc,
    ProfileBankAccount,
    ProfileBusinessReg,
  },
  created: function() {
    this.getPersanalInfo();
    this.businessRegShow();
    this.handleBreadcrumb();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    switchToBusi() {
      this.switchToBusiness = 1;
      this.info.account_type = 2;
      this.activetab = 6;
    },
    businessRegShow: function() {
      let userDetails = JSON.parse(localStorage.merchant_data);
      this.info = userDetails;
    },
    kycComplete(objec) {
      this.kycInfo = objec;
    },
    ActiveKycTab() {
      this.activetab = 3;
    },
    pushStore: function() {
      this.$router.push("/merchant-store-configure/0");
    },
    activateTab(tabnum) {
      tabnum = tabnum + 1;
      if (tabnum < 10) {

        if (this.account_type === 1) {
          if (this.currentstage === 4) {
            let self = this;
            this.$swal({
              title: "Please Create A Store",
              text: "",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result) {
                self.pushStore();
              }
              
            });
            return;
          }
        }

        if (this.account_type === 2) {
          if (this.currentstage === 4 && this.activetab !== 6) {
            this.$swal({
              title: "Please Create Business Information",
              text: "",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result) {
                this.activetab = 6;
              }
              
            });
            return;
          }
        }

        if (this.account_type === 2) {
          if (this.currentstage === 5) {
            let self = this;
            this.$swal({
              title: "Please Create A Store",
              text: "",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result) {
                self.pushStore();
              }
              
            });
            return;
          }
        }


        // if (this.account_type === 2) {
        //   if (tabnum === 5) {
        //     let self = this;
        //     this.$swal({
        //       title: "Please Create A Store",
        //       text: "",
        //       type: "warning",
        //       showCancelButton: false,
        //       confirmButtonColor: "#3085d6",
        //       confirmButtonText: "OK",
        //     }).then((result) => {
        //       if (result) {
        //         self.pushStore();
        //       }
        //     });
        //     return;
        //   }
        // }

        // if (this.account_type === 2) {
        //   if (this.currentstage === 6) {
        //   let self = this;
        //     this.$swal({
        //       title: "Please Create A Store",
        //       text: "",
        //       type: "warning",
        //       showCancelButton: false,
        //       confirmButtonColor: "#3085d6",
        //       confirmButtonText: "OK",
        //     }).then((result) => {
        //       if (result) {
        //         self.pushStore();
        //       }
        //     });
        //     return;
        //   }
        // }
        // alert(tabnum)

        
        if (tabnum === 2 && this.activetab !== 2) {
          this.$swal({
            title: "Please Update Address Information",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            this.activetab = 2;
          });
        }

        if (tabnum === 3 && this.activetab !== 3) {
          this.$swal({
            title: "Please Update KYC Information",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            this.activetab = 3;
          });
        }

        if (tabnum === 4 && this.activetab !== 4) {
          this.$swal({
            title: "Please Update Bank Information",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            this.activetab = 4;
          });
        }

        if (this.currentstage === 6 && this.activetab !== 9) {
          this.$swal({
            title: "Please Submit Delivery Information",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result) {
              this.activetab = 9;
            }
            
          });
          return;
        }

      }
    },
    checkRequiredStage: async function() {
      let response = await this.getDashboardStage();

      if (response !== null) {
        this.currentstage = response.complated_status;
        this.activateTab(this.currentstage);
        return response.complated_status;
      } else {
        this.currentstage = 1;
        this.activateTab(this.currentstage);
        return null;
      }
    },
    postRequiredStage: async function(stage) {
      let obj = {
        complated_status: stage,
      };
      let response = await this.postDashboardStage(obj);
    },
    completeKyc1: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        if (response.kyc_lists.length === 0) {
          this.$swal({
            title: "Please Submit KYC",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            this.ActiveKycTab();
          });
        }
      } catch (error) {
        throw error;
      }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.account_type = response.account_type;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<style scoped>
.removeBg:hover {
  background-color: transparent !important;
  border: none !important;
  color: black;
}
.removeBg:focus {
  background-color: transparent !important;
  border: none !important;
  color: black;
}
@media (min-width: 768px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 69vh !important;
  }
}
@media (min-width: 1280px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 76vh !important;
  }
}
@media (min-width: 1440px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 67vh !important;
  }
}
@media (min-width: 1800px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 33vh !important;
  }
}
@media (min-width: 1858px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 63vh !important;
  }
}
@media (min-width: 1880px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 33vh !important;
  }
}
@media (min-width: 2880px) {
  .scopedClass {
    margin-bottom: 0px !important;
    min-height: 34vh !important;
  }
}

.well {
  background-color: transparent !important;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  /* content: counter(step); */
  content: no-close-quote;
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: rgb(77, 161, 48);
}
.progressbar li.active:before {
  border-color: rgb(77, 161, 48);
  /* background-color: rgb(77, 161, 48); */
  /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
  background-image: url("/assets/image/sample_product_images/confirm1.png");
  background-size: contain;
}
.progressbar li.active + li:after {
  background-color: rgb(77, 161, 48);
}

.progressbar li.dfailed:before {
  border-color: red;
  background-color: red !important;
  /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
  background-image: url("https://www.shareicon.net/data/256x256/2015/09/15/101562_incorrect_512x512.png");
  background-size: contain;
}
</style>
