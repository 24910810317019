<template>
<div  v-if="basicInfo.kyc_lists" >
  <p class="lead" style="font-size: 18px;">Business Contact Person</p>
  <p style="color: #5779ae; font-size: 17px;" class="lead">Hello, <strong>{{ basicInfo.first_name }} {{ basicInfo.last_name }}</strong> - <label style="color: #6a6a6a; font-size: 17px;">Please upload your KYC information.</label></p>
  <div class="row" style="margin-bottom: 20px;">
    <div class="col-sm-5">
      <label for="input-payment-country" class="control-label">Type</label>

      <select class="form-control select-arrow" id="input-payment-country" name="country_id" @change="changeType($event.target.value)" v-model="saveImgObject.kyc_type" disabled>
        <option disabled value="-1"> --- Select --- </option>
        <option value="1">NIC Number</option>
        <option value="2">Passport Number</option>
      </select>
    </div>
    <div class="col-sm-7"></div>
  </div>

  <div class="row" style="margin-bottom: 5px;">
    <div class="col-sm-5">
      <div class="form-group">
          <label for="input-middlename" class="control-label">{{ typeName }}</label>
          <input type="text" class="form-control" :style="changeBorder" @keyup="validateNumber(saveImgObject.card_no, saveImgObject.kyc_type)" :placeholder="typeName.split(' (')[0]" v-model="saveImgObject.card_no" disabled>
          <!-- <input type="text" class="form-control" :placeholder="typeName" v-model="saveImgObject.card_no" :disabled="isDisable">    -->
      </div>
    </div>
    <div class="col-sm-7"></div>
  </div>

  <div class="row" style="margin-bottom: 5px;">
    <div class="col-sm-5">
      <div class="form-group required">
        <label for="input-middlename" class="control-label">{{ nameOnThe }}</label>
        <input type="text" class="form-control" :placeholder="nameOnThe" v-model="saveImgObject.name" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)">
        <!-- <input type="text" class="form-control" :placeholder="nameOnThe" v-model="saveImgObject.name" :disabled="isDisable"> -->
      </div>
    </div>
    <div class="col-sm-2"></div>
  </div>
  
  <div class="row">
    <div class="col-sm-4">
      <p style="font-size: 16px; color: red;">* Please upload your images</p>
      <div style="margin-left: 10px;">
        <p style="color: red">* All four corners to be visible.</p>
        <p style="color: red">* The photo of the ID/Document should be in high quality.</p>
        <p style="color: red">* We accept .Jpg / .PNG & PDF formats only, Must be below 3MB.</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-5">
      <h5><b v-if="basicInfo.kyc_lists[0]">{{ basicInfo.kyc_lists[0].image_approve_status }}</b></h5>
      <h5><b v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].image_reject_reason && basicInfo.kyc_lists[0].image_approve_status === 'rejected'">Reject Reason: - {{ basicInfo.kyc_lists[0].image_reject_reason.reason }}</b></h5>
      <div v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].image_reject_reason_description && basicInfo.kyc_lists[0].image_approve_status === 'rejected'" v-html="basicInfo.kyc_lists[0].image_reject_reason_description"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-5">
      <p>{{ front }}</p>
      <img v-if="!isPdf" style="width: auto; height: 200px;" slot="image" class="card-img-top" :src="contactImage1" alt="Card image cap">
      <iframe
        v-if="isPdf"
        :src="contactImage1"
        frameBorder="0"
        scrolling="none"
        height="325px"
        width="100%"
      ></iframe>
      <br>
      <br>
      <button v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].image_approve_status === 'pending'|| basicInfo.kyc_lists[0].image_approve_status === 'resubmit'" class="btn btn-sm merchprof-sbtn" @click="pickKycProf1()">Upload</button>
      <input v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].image_approve_status === 'pending'|| basicInfo.kyc_lists[0].image_approve_status === 'resubmit'" 
        type="file" 
        class="inputfile" 
        @change="getFile1"
        id="embedpollfileinput"
        ref="upprofpicturemer1"
        style="display: none;">
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-5">
      <p>{{ back }}</p>
      <!-- <h5><b v-if="basicInfo.kyc_lists[0]">{{ basicInfo.kyc_lists[0].image_approve_status }}</b></h5>
      <h5><b v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].image_reject_reason && basicInfo.kyc_lists[0].image_approve_status === 'rejected'">Reject Reason: - {{ basicInfo.kyc_lists[0].image_reject_reason.reason }}</b></h5>
      <div v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].image_reject_reason_description && basicInfo.kyc_lists[0].image_approve_status === 'rejected'" v-html="basicInfo.kyc_lists[0].image_reject_reason_description"></div> -->
      <img v-if="!isPdf1" style="width: auto; height: 200px;" slot="image" class="card-img-top" :src="contactImage2" alt="Card image cap">
      <iframe
        v-if="isPdf1"
        :src="contactImage2"
        frameBorder="0"
        scrolling="none"
        height="325px"
        width="100%"
      ></iframe>
      <br>
      <br>
      <button v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].image_approve_status === 'pending'|| basicInfo.kyc_lists[0].image_approve_status === 'resubmit'" class="btn btn-sm merchprof-sbtn" @click="pickKycProf2()">Upload</button>
      <input v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].image_approve_status === 'pending'|| basicInfo.kyc_lists[0].image_approve_status === 'resubmit'" 
              type="file" 
              class="inputfile" 
              @change="getFile2"
              id="embedpollfileinput1"
              ref="upprofpicturemer2"
              style="display: none;">
    </div>
    <div class="col-sm-1"></div>
  </div>
<!-- v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_one_approve_status === 'rejected'|| basicInfo.kyc_lists[0].image_two_approve_status === 'rejected'" -->
  <div class="row"  v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].image_approve_status === 'pending'|| basicInfo.kyc_lists[0].image_approve_status === 'resubmit'">
    <div class="col-sm-8" style="margin-top: 30px; margin-left: 15px;">
        <div class="form-group text-right" >
            <button v-if="!isLoading" class="btn merchprof-sbtn ml-5" :disabled="!formIsValid" @click="submitKyc">Submit</button>
        </div>
    </div>
  </div>
  <div class="row" v-if="isLoading">
    <div class="col-sm-12 footerClass2">
      <div class="text-right">
        <div style="display: flex; width: 140px; justify-content: end; flex-direction: column; align-items: unset; float: right;">
          <button
            disabled
            style="color: white;"
            class="btn merchprof-sbtn"
          >Loading...
          </button>
          <progress-bar
            style="margin-top: 10px;"
            :options="options"
            :value="loadingprogress"
          />
        </div>
      </div>
    </div>
  </div>


  <div v-if="isVisible">
  <hr/>

  <p style="color: #0562a8; font-size: 17px;" class="lead"><label style="color: #6a6a6a; font-size: 17px;">Please upload your KYC (address) information.</label></p>
  <address-view /><br/>
  <div class="row">
    <div class="col-sm-4">
      <p style="font-size: 16px; color: red">* Please upload your address proof</p>
      <div style="margin-left: 10px;">
        <p style="color: red">* All four corners to be visible.</p>
        <p style="color: red">* The photo of the address proof should be in high quality.</p>
        <p style="color: red">* We accept .Jpg / .PNG & PDF formats only, Must be below 3MB.</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <!-- <p>address</p>  -->
      <h5><b v-if="basicInfo.kyc_lists[0]">{{ basicInfo.kyc_lists[0].address_image_approve_status }}</b></h5>
      <h5><b v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].address_image_reject_reason && basicInfo.kyc_lists[0].address_image_approve_status === 'rejected'">Reject Reason: - {{ basicInfo.kyc_lists[0].address_image_reject_reason.reason }}</b></h5>
      <div v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].address_image_reject_reason_description && basicInfo.kyc_lists[0].address_image_approve_status === 'rejected'" v-html="basicInfo.kyc_lists[0].address_image_reject_reason_description"></div>
      <img v-if="!isPdf2" style="width: auto; height: 200px;" slot="image" class="card-img-top" :src="contactImage3" alt="Card image cap">
      <iframe
        v-if="isPdf2"
        :src="contactImage3"
        frameBorder="0"
        scrolling="none"
        height="325px"
        width="100%"
      ></iframe>
      <br>
      <br>
      <button v-if="!basicInfo.kyc_lists[0] || !basicInfo.kyc_lists[0].address_image_approve_status || basicInfo.kyc_lists[0].address_image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].address_image_approve_status === 'pending'|| basicInfo.kyc_lists[0].address_image_approve_status === 'resubmit'" class="btn btn-sm merchprof-sbtn" @click="pickKycProf3()">Upload</button>
      <input v-if="!basicInfo.kyc_lists[0] || !basicInfo.kyc_lists[0].address_image_approve_status || basicInfo.kyc_lists[0].address_image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].address_image_approve_status === 'pending'|| basicInfo.kyc_lists[0].address_image_approve_status === 'resubmit'" 
        type="file" 
        class="inputfile" 
        @change="getFile3"
        id="embedpollfileinput2"
        ref="upprofpicturemer3"
        style="display: none;">
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-4"></div>
    <div class="col-sm-3"></div>
  </div>

  <!-- <div class="row" v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_one_approve_status === 'rejected' || basicInfo.kyc_lists[0].image_two_approve_status === 'rejected'"> -->
  <div class="row" v-if="!basicInfo.kyc_lists[0] || !basicInfo.kyc_lists[0].address_image_approve_status || basicInfo.kyc_lists[0].address_image_approve_status === 'rejected'|| basicInfo.kyc_lists[0].address_image_approve_status === 'pending'|| basicInfo.kyc_lists[0].address_image_approve_status === 'resubmit'">
    <div class="col-sm-8" style="margin-top: 30px; margin-left: 15px;">
        <div class="form-group text-right" >
            <button v-if="!isLoading1" class="btn merchprof-sbtn ml-5" :disabled="!formIsValid1" @click="submitKycAddress">Submit</button>
        </div>
    </div>
  </div>
  <div class="row" v-if="isLoading1">
    <div class="col-sm-12 footerClass2">
      <div class="text-right">
        <div style="display: flex; width: 140px; justify-content: end; flex-direction: column; align-items: unset; float: right;">
          <button
            disabled
            style="color: white;"
            class="btn merchprof-sbtn"
          >Loading...
          </button>
          <progress-bar
            style="margin-top: 10px;"
            :options="options"
            :value="loadingprogress"
          />
        </div>
      </div>
    </div>
  </div>
  </div>
</div>  
</template>
<script>
import mixinProfile from "../../../mixins/merchant/APIProfile";
import addressView from "./addressView";
export default {
  mixins: [mixinProfile],
  components: {
    addressView
  },
  data: function () {
    return {
        loadingprogress: 0,
        basicInfo: {},
        changeBorder: {
          border: "1px solid #ccc"
        },
        fileUploadFormData: new FormData(),
        image1: "",
        image2: "",
        image3: "",
        imgCon: "",
        imgCon1: "",
        imgCon2: "",
        img1: "",
        img2: "",
        img3: "",
        isPdf: false,
        isPdf1: false,
        isPdf2: false,
        typeName: "Please select Type",
        nameOnThe: "Please select Type",
        isDisable: true,
        isNicValid: true,
        passportVisible: true,
        front: "",
        back: "",
        saveImgObject: {
          kyc_type: "-1"
        },
        isVisible: false,
        isLoading1: false,
        isLoading: false,
        options: {
          text: {
            color: '#FFFFFF',
            shadowEnable: true,
            shadowColor: '#000000',
            fontSize: 14,
            fontFamily: 'Helvetica',
            dynamicPosition: false,
            hideText: false
          },
          progress: {
            color: '#2dbd2d',
            backgroundColor: '#C0C0C0'
          },
          layout: {
            height: 30,
            width: 140,
            verticalTextAlign: 61,
            horizontalTextAlign: 43,
            zeroOffset: 0,
            strokeWidth: 30,
            progressPadding: 0,
            type: 'line'
          }
        }
    }
   },
   computed: {
    formIsValid() {
      return (
        this.imgCon &&
        this.imgCon1 &&
        this.saveImgObject.card_no &&
        this.saveImgObject.name &&
        this.isNicValid
      );
    },
    formIsValid1() {
      return (
        this.imgCon2
      );
    },
    contactImage1: function() {
      if (this.image1) {
        this.imgCon = 1;
        return this.image1;
      } else {
        this.imgCon = "";
        return "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629963486/cochchi/mercahnt/1629963485777.webp";
      }
    },
    contactImage2: function() {
      if (this.image2) {
        this.imgCon1 = 1;
        return this.image2;
      } else {
        this.imgCon1 = "";
        return "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629963578/cochchi/mercahnt/1629963577937.webp";
      }
    },
    contactImage3: function() {
      if (this.image3) {
        this.imgCon2 = 1;
        return this.image3;
      } else {
        this.imgCon2 = "";
        return "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629963661/cochchi/mercahnt/1629963660175.webp";
      }
    }
   },
   created() {
    this.getPersanalInfo();
   },
   methods: {
     pickKycProf1: function() {
      this.$refs.upprofpicturemer1.click();
    },
    pickKycProf2: function() {
      this.$refs.upprofpicturemer2.click();
    },
    pickKycProf3: function() {
      this.$refs.upprofpicturemer3.click();
    },
    validateNumber(str, condition) {       // str = input value,    condition = nic or passport type
      if (condition === 1) {
        if (str !== "") {
          if (/^[0-9]{9}[vVxX]$/.test(str)) {
            this.isNicValid = true;
            this.changeBorder.border = "1px solid green";
          } else {
            if (/^[0-9]{12}$/.test(str)) {
              this.isNicValid = true;
              this.changeBorder.border = "1px solid green";
            } else {
              this.isNicValid = false;
              this.changeBorder.border = "1px solid red";
            }
          }
        } else {
          this.isNicValid = false;
          this.changeBorder.border = "1px solid red";
        }
      } else if (condition === 2) {
        if (str !== "") {
          if (/^([A-Z a-z]){1}([0-9]){7}$/.test(str)) {
            this.isNicValid = true;
            this.changeBorder.border = "1px solid green";
          } else {
            this.isNicValid = false;
            this.changeBorder.border = "1px solid red";
          }
        } else {
          this.isNicValid = false;
          this.changeBorder.border = "1px solid red";
        }
      }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.$parent.checkRequiredStage();
        this.basicInfo = response;
        // console.log("this.basicInfo",this.basicInfo);
        if (this.basicInfo.if_proof_type) {
          this.saveImgObject.kyc_type = this.basicInfo.if_proof_type;
          this.saveImgObject.card_no = this.basicInfo.id_proof;
          if (this.basicInfo.if_proof_type === 1) {
              this.front = "ID Front";
              this.back = "ID Back";
              this.typeName = "ID Number";
              this.nameOnThe = "Name on the ID Card/ NIC"
          } else if (this.basicInfo.if_proof_type === 2) {
              this.front = "Passport Image 1";
              this.back = "Passport Image 2";
              this.typeName = "Passport Number";
              this.nameOnThe = "Name on Passports"
          }
        }

        if (response.kyc_lists.length !== 0) {
          for (let i = 0; i < response.kyc_lists.length; i++) {
            this.saveImgObject = response.kyc_lists[i];
            this.image1 = response.kyc_lists[i].image1;
            this.image2 = response.kyc_lists[i].image2;
            this.image3 = response.kyc_lists[i].address_image;
            
            if (this.image1 && this.image2) {
              this.isVisible = true
            }
            
            if (response.kyc_lists[i].kyc_type === 1) {
              this.front = "ID Front";
              this.back = "ID Back";
              this.typeName = "ID Number";
              this.nameOnThe = "Name on the ID Number"
            } else if (response.kyc_lists[i].kyc_type === 2) {
              this.front = "Passport Front";
              this.back = "Passport Front";
              this.typeName = "Passport Number";
              this.nameOnThe = "Name on the Passport Number"
            } else if (response.kyc_lists[i].kyc_type === 3) {
              this.front = "Driving License Front";
              this.back = "Driving License Back";
              this.typeName = "Driving License Number";
              this.nameOnThe = "Name on the License Number"
            }
          }
        }
        if (this.image1.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf = true;
        } 
        else {
          this.isPdf = false;
        }

        if (this.image2.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf1 = true;
        } 
        else {
          this.isPdf1 = false;
        }

        if (this.image3.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf2 = true;
        } 
        else {
          this.isPdf2 = false;
        }
        this.saveImgObject.kyc_type = this.basicInfo.if_proof_type;
      } catch (error) {
        console.log(error);
      }
    },
    changeType: function(value) {
      this.saveImgObject.card_no = "";
      this.saveImgObject.name = "";
      if (value === "1") {
        this.saveImgObject.kyc_type = 1;
        this.typeName = "ID Number ( xxxxxxxxxV/X or xxxxxxxxxxxx )";
        this.nameOnThe = "Name on the ID Number"
        this.front = "ID Front";
        this.back = "ID Back";
        this.isDisable = false;
        this.passportVisible = true;
      } else if (value === "2") {
        this.saveImgObject.kyc_type = 2;
        this.typeName = "Passport Number ( NXXXXXXX )";
        this.nameOnThe = "Name on the Passport Number"
        this.front = "Passport Front";
        this.isDisable = false;
        this.passportVisible = false;
      } else if (value === "3") {
        this.saveImgObject.kyc_type = 3;
        this.front = "Driving License Front";
        this.back = "Driving License Back";
        this.typeName = "Driving License Number";
        this.nameOnThe = "Name on the License Number"
        this.isDisable = false;
        this.passportVisible = true;
      } else {
        this.isDisable = true;
        this.passportVisible = true;
      }
    },
    getFile1: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf = true;
      } 
      else {
        this.isPdf = false;
      }
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image1 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image1 = tgt.files[0];
      this.img1 = eventf.target.files[0]
    },
    getFile2: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput1');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf1 = true;
      } 
      else {
        this.isPdf1 = false;
      }
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image2 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image2 = tgt.files[0];
      this.img2 = eventf.target.files[0]
    },
    getFile3: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput2');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf2 = true;
      } 
      else {
        this.isPdf2 = false;
      }
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image3 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image3 = tgt.files[0];
      this.img3 = eventf.target.files[0]
    },
    submitKyc: async function() {
    this.isLoading = true;
    this.loadingprogress = 10;
     await this.fileUploadFormData.set("kyc_type", this.saveImgObject.kyc_type);
     await this.fileUploadFormData.set("card_no", this.saveImgObject.card_no);
     await this.fileUploadFormData.set("name", this.saveImgObject.name);
     if (this.img1 !== "") {
       await this.fileUploadFormData.append("image", this.img1);
     } else {
      await this.fileUploadFormData.set("image1", this.image1);
     }
     if (this.img2 !== "") {
       await this.fileUploadFormData.append("image1", this.img2);
     } else {
      await this.fileUploadFormData.set("image2", this.image2);
     }

     let formData = await this.fileUploadFormData;

     try {
       this.loadingprogress = 25;
       console.log("this.formData",this.formData)
      await this.putKyc(formData);

      // KYC Aprovation
      this.loadingprogress = 100;
      this.isLoading = false;
      // this.checkKYCAprovation();

      // await this.$parent.postRequiredStage(3);
      // let self = this;
      this.$swal.fire({
        position: 'center',
        type: 'success',
        title: 'Submit success',
        showConfirmButton: false,
        timer: 1500
      }).then(function() {                  
        // self.$parent.checkRequiredStage();
      })  
      this.getPersanalInfo();
      this.fileUploadFormData = new FormData();
     } catch (error) {
        this.$swal.fire({
        position: "center",
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
        });
        this.isLoading = false;
        this.fileUploadFormData = new FormData()
     }
    },
    submitKycAddress: async function() {
    this.isLoading1 = true;
    this.loadingprogress = 10;
    let addressKycType = 4;
     await this.fileUploadFormData.set("kyc_type", addressKycType);
     await this.fileUploadFormData.append("image", this.img3);

     let formData = await this.fileUploadFormData;

     try {
      this.loadingprogress = 20;
      await this.putAddressKyc(formData);
      
      // KYC Aprovation
      let response = await this.getProfileBacicInfo();
      if (response.kyc_lists.length > 0 && response.kyc_lists[0].address_image_approve_status === 'pending') {
        await this.$parent.postRequiredStage(3);
        await this.$parent.checkRequiredStage();

      } else {
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'Submit address kyc success',
          showConfirmButton: false,
          timer: 1500
        });
        this.fileUploadFormData = new FormData();  
        this.getPersanalInfo();
      }
      this.loadingprogress = 100;
      this.isLoading1 = false;
     } catch (error) {
      this.$swal.fire({
        position: "center",
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
        });
        this.isLoading1 = false;
        this.fileUploadFormData = new FormData()
        
     }
    },
    checkKYCAprovation: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        if (response.kyc_lists.length > 0 && response.kyc_lists[0].image_approve_status === 'pending' && response.kyc_lists[0].address_image_approve_status === 'pending') {
          await this.$parent.postRequiredStage(3);
          await this.$parent.checkRequiredStage();
        }
      } catch (error) {
        console.log(error);
      }
        
    }
  }
}
</script>
<style scoped>
.merchprof-sbtn{
  background: #5779ae;
  color: #000000;
  border: none;
}
.merchprof-sbtn:hover{
  color: #ffffff;
  background: #5779ae;
}
</style>