<template>
  <div class="merchentitle">
    <div class="row">
      <div class="col-md-12">
        <h2 class="title">Delivery Partner</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" style="display: flex; align-items: center;">
        <div class="form-group" style="margin-right: 4%;">
          <input
            class="ml-5"
            type="checkbox"
            v-model="thired_party"
            @change="enableThiredParty(thired_party)"
            name="Thired Party Delivery"
          />&nbsp; Third Party Delivery
        </div>
        <div class="form-group">
          <input
            class="ml-5"
            type="checkbox"
            disabled
            name="My Own Delivery"
          />&nbsp; My Own Delivery
          <!-- <input
            class="ml-5"
            type="checkbox"
            v-model="my_own"
            @change="enableMyOwn(my_own)"
            name="My Own Delivery"
          />&nbsp; My Own Delivery -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>Cochchi.lk currently support Third Party Delivery (Cochchi.lk Standard Delivery) Only. Own Delivery facility is temporary not available.</p>
      </div>
    </div>
    <div class="row" v-if="my_own">
      <div class="col-md-12">
        <div style="display: flex; align-items: center; flex-direction: column; padding: 20px;" v-if="!is_delivary && !is_create_delivary ">
          <button class="btn btn-primary" @click="is_create_delivary = true" >Create My Delivery Profile</button>
        </div>
        <div style="display: flex; align-items: center; flex-direction: column; padding: 20px;"  v-if="is_delivary">
          <h4 style="color: red;" v-if="this.delivaryActiveStatus !== 'Active'">Your Delivery Account Now on {{delivaryActiveStatus}} Status</h4>
          <h4>Please Visit Delivery Portal to Setup Delivery Charges</h4><button @click="visitDelivary()" class="btn btn-primary" >Visit Delivery Portal</button>
        </div>

        <div v-if="is_create_delivary" style="display: flex; align-items: center; flex-direction: column; background-color: #e6e4e4; padding: 20px;">
          <div class="form-group">
            <input
              class="ml-5"
              type="checkbox"
              v-model="agree_terms_Delivary"
              name="My Own Delivery"
            />&nbsp; By Creating Delivery Account, I Agree to the Cochchi.lk User Terms & Conditions and Privacy Policy 
          </div>
          <button :disabled="!agree_terms_Delivary" @click="createDelivary()"  class="btn btn-primary" >Create My Delivery Profile</button>
        </div>
      </div>
    </div>
    <div class="row"  v-if="thired_party">
      <div class="col-md-12">
        <div v-if="thired_party" class="form-group required">
          <label class="control-label">Delivery Partner</label>
          <select
            class="form-control select-arrow"
            v-model="selecteddeliverypartner"
          >
            <option
              v-for="(item, index) in deliverpartners"
              :key="index"
              :value="item._id"
            >{{ item.first_name + " " + item.last_name }}</option>
          </select>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn-primary pull-right" :disabled="!selecteddeliverypartner" @click="deliverySubmit">Submit</button>
            </div>
          </div>
          <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">View your hub stations</button>
      </div>
    </div>
    <div
      class="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLongTitle">
              Grasshopper Delivery Hub Stations
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                style="position: absolute; right: 17px; top: 15px"
                >&times;</span
              >
            </button>
          </div>
          <div
            class="modal-body"
          >
          <div class="row">
            <div class="col-md-12" style="overflow-y: scroll;">
              <table id="hubTable" border="1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Hub Owner</th>
                    <th style="width: 50%">Address</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in grasshopperhubs" :key="index">
                    <td style="min-width: 200px;">{{item.name}}</td>
                    <td>{{item.hub_owner}}</td>
                    <td>{{item.address}}</td>
                    <td>{{item.mobile}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          </div>
        </div>
      </div>
    </div>
  </div> 
</template>
<script>
import mixinProduct from "../../../mixins/merchant/APIProduct";
import mixinProfile from "../../../mixins/merchant/APIProfile";
export default {
  mixins: [mixinProduct,mixinProfile],
  components: {},
  data () {
    return {
      thired_party: true,
      my_own: false,
      is_delivary: false,
      is_create_delivary: false,
      agree_terms_Delivary: false,
      delivaryActiveStatus: "",
      selecteddeliverypartner: "",
      ownDelivreyPartner:"",
      deliverpartners: [],
      basicInfo:{},
      deliverDetails:[],
      grasshopperhubs:[

      {
        id: 1, 
        name: "Akuressa / Neluwa hub", 
        hub_owner: "Ajith", 
        address: "'Viraj'edandukita,alapaladeniya,morawaka,matara.", 
        mobile: "0763371042"}, 
      {
        id: 2, 
        name: "Ampara New hub", 
        hub_owner: "Pradeep", 
        address: "21/118/3 Thissapura Uhana", 
        mobile: "0715516863"}, 
      {
        id: 3, 
        name: "Athurugiriya New Hub", 
        hub_owner: "Viran Fernando", 
        address: "1212 Abeythissa Road Millenium City, Athurugiriya", 
        mobile: "0757094276"}, 
      {
        id: 4, 
        name: "Avissawella  Hub", 
        hub_owner: "Lasantha Devapriya", 
        address: "410/3 Minnana, Getahetha", 
        mobile: "0773013513"}, 
      {
        id: 5, 
        name: "Anuradhapura New Hub", 
        hub_owner: "Ranjith", 
        address: "No 215 Temple cross Street Thannayamkulama, Anuradhapura", 
        mobile: "0728916712"}, 
      {
        id: 6, 
        name: "Bandarawela City hub", 
        hub_owner: "P.G.Chamod Lahiru Sandamal", 
        address: "Queen Town Estate, upper divison, Hali Ela.", 
        mobile: "0761831672"}, 
      {
        id: 7, 
        name: "Batticaloa Hub", 
        hub_owner: "SLM Niqras", 
        address: "296D,Haniffa Road, Kalmunai-07", 
        mobile: "0777559492"}, 
      {
        id: 8, 
        name: "Dambulla City hub", 
        hub_owner: "Mithun Wishva Jyantha", 
        address: "Sigiriya junction, inamaluwa, Dambulla", 
        mobile: "0766868005"}, 
      {
        id: 9, 
        name: "Dehiattakandiya Super Hub", 
        hub_owner: "M.M.E.G.Sunil Bandara", 
        address: "NO.261,Vijeyapura,Damanewela", 
        mobile: "0762531650/ 0776025048"}, 
      {
        id: 10, 
        name: "Embilipitiya Hub", 
        hub_owner: "J A H M  Kumara ", 
        address: " No.30/C,Aluthwatta,Dabaralla  -Kariyamadiththa", 
        mobile: "0776639505"}, 
      {
        id: 11, 
        name: "Galle hub I -  II", 
        hub_owner: "Manjula walpitagama ", 
        address: "148,kalahe ,wanchawala ,galle ", 
        mobile: "0772867575"}, 
      {
        id: 12, 
        name: "Ganemaulla Hub", 
        hub_owner: "yawwanaka prabath kumara", 
        address: "No.189 /e, jaela Road,Horagolla", 
        mobile: "0778574900"}, 
      {
        id: 13, 
        name: "Jaffna super hub ", 
        hub_owner: "R.Premkumar", 
        address: "41/53 Chettiyar thoddam Nallur Jaffna ", 
        mobile: "0776281557"}, 
      {
        id: 14, 
        name: "Kaduwela Hub", 
        hub_owner: "Amila", 
        address: "No 291/2C, Meegasmulla, Dedigamuwa", 
        mobile: "0777481218"}, 
      {
        id: 15, 
        name: "Kalmunai hub ( Ampara hub)", 
        hub_owner: "Abdul Razzak Siraj", 
        address: "165/1Kabeer Lane Maruthamunai", 
        mobile: "0750714828"}, 
      {
        id: 16, 
        name: "Kaluthara Hub", 
        hub_owner: "Malith wijayawardana ", 
        address: "No 17 Pahala naragala. govinna, horana", 
        mobile: "0772245267"}, 
      {
        id: 17, 
        name: "Kandana Hub ", 
        hub_owner: "H. D. N. Jayantha ", 
        address: "74/5 Aniyakanda watta, Nagoda , Kandana .", 
        mobile: "0764330065"}, 
      {
        id: 18, 
        name: "Kandy CityHub", 
        hub_owner: "Dinuka Sampath", 
        address: "No.157/C/3,Peradeniya Road,Halloluwa", 
        mobile: "0711052910"}, 
      {
        id: 19, 
        name: "Kilinochi new hub", 
        hub_owner: "J. Sajith Osahan", 
        address: "Eladuwa Watta, Mayugama", 
        mobile: "0773173193"}, 
      {
        id: 20, 
        name: "Kuliyapitiya New Hub", 
        hub_owner: " Rasika Lakshan Herath", 
        address: " Ihalagama south, udubaddawa, Kuliyapitiya", 
        mobile: "0775711743"}, 
      {
        id: 21, 
        name: "Kurunegala Hub", 
        hub_owner: "Ahamed Anver", 
        address: "61,Puttlam Road Kurunrgala", 
        mobile: "0766559844"}, 
      {
        id: 22, 
        name: "Kelaniya Hub", 
        hub_owner: "Gnanasekara Kasun Madusanka ", 
        address: "No 05/C, Aniyakanda Hospital Road, Nagoda,Kandana", 
        mobile: "0778587583"}, 
      {
        id: 23, 
        name: "Mahiyanaganaya hub", 
        hub_owner: "J.M.B. Lakmal Jayasundara", 
        address: "No 29 Jana Udanagama welampala, Arawatta", 
        mobile: "0703395771"}, 
      {
        id: 24, 
        name: "Mathegoda Hub", 
        hub_owner: "Sasitha William", 
        address: "225/2 Horana Road, Kottawa", 
        mobile: "0750767343"}, 
      {
        id: 25, 
        name: "Mathugama Hub", 
        hub_owner: "Ruwan Chandima ", 
        address: "80/A idalgoda North,Mathugama ", 
        mobile: "0768761804"}, 
      {
        id: 26, 
        name: "Mawanella Hub", 
        hub_owner: "Yasitha Abeyrathna", 
        address: "No. 570 Uthuwankanda, Mawanella", 
        mobile: "0770674026"}, 
      {
        id: 27, 
        name: "Meerigama Hub", 
        hub_owner: "Abeykoon Bandara ", 
        address: "PAHALA BUJJOMUWA, BUJJOMUWA, ALAWWA", 
        mobile: "0703517998"}, 
      {
        id: 28, 
        name: "Melsiripura New Hub", 
        hub_owner: "Amal", 
        address: "Suba Niwasa, Ilukewela, Weuda, Mawathagama", 
        mobile: "0773623595"}, 
      {
        id: 29, 
        name: "Modara (Rajas) Hub", 
        hub_owner: "Chrishantha Pradeep", 
        address: "No 1/97 Farm Road, Mattakkuliya Colombo 15", 
        mobile: "0752222967"}, 
      {
        id: 30, 
        name: "Monaragala New hub", 
        hub_owner: "Suranga", 
        address: "13,Mile post, monaragala Road, Mahagodayaya, Buttala.", 
        mobile: "0760483917"}, 
      {
        id: 31, 
        name: "Negombo City hub ", 
        hub_owner: "Nishantha Nadaraj ", 
        address: "No. 147/2/G, Golden terace, pahala Kadirana, Thibirigaskatuwa ", 
        mobile: "0778873170"}, 
      {
        id: 32, 
        name: "Moratuwa Hub", 
        hub_owner: "Lakshan de silva", 
        address: "No 17 Janatha place, Katubadda, Moratuwa,", 
        mobile: "0757158382"}, 
      {
        id: 33, 
        name: "Polannaruwa Hub", 
        hub_owner: "Gamage Pushpakumara", 
        address: "23th mile post,Jayanthipura,Polonnaruwa", 
        mobile: "0716946073"}, 
      {
        id: 34, 
        name: "Queens Hub Maradana / Kadawatha", 
        hub_owner: "Farzana", 
        address: "230/20, Messenger Street, Colombo 12", 
        mobile: "0701724656"}, 
      {
        id: 35, 
        name: "Rathnapura Hub", 
        hub_owner: "Nishantha Pushpakumara", 
        address: "928,Eknaligoda,Kuruwita, - Ratnapura", 
        mobile: "0762599745"}, 
      {
        id: 36, 
        name: "Thissa Hub", 
        hub_owner: "Prabhash Silva ", 
        address: "677,Rohanapura , Debarawewa , Thissamaharama.", 
        mobile: "0775800685"}, 
      {
        id: 37, 
        name: "Trinco New Hub", 
        hub_owner: "Nagaraja Janarthanan", 
        address: "92 malinthurai kinniya 04", 
        mobile: "0769825768"}, 
      {
        id: 38, 
        name: "Vavuniya Hub", 
        hub_owner: "Ochashathevan teavadas", 
        address: "Vamathevapuram adampa, Mannar", 
        mobile: "0766994892"}, 
      {
        id: 39, 
        name: "Wellawatta Hub ", 
        hub_owner: "M.I.M.Izzat ", 
        address: "FU 2/3 dias Place, Gunasinghapura - Colombo12", 
        mobile: "0714842244"}
      ],
      breadcrumb: [
        {
          text: "News Feeds",
          href: "/merchant-dashbord",
          active: false
        },
      ]
    }
  },
  watch: {},
  created() {
    this.handleBreadcrumb();
    this.fetchDeliveryPartners();
    this.getDelivaryOwn();
    this.getPersanalInfo();
  },
  methods: {
    handleBreadcrumb() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getPersanalInfo: async function() {
      try {
        this.$parent.checkRequiredStage();
        let response = await this.getProfileBacicInfo();
        this.basicInfo = response;
        if (this.basicInfo.deliver) {
          if (this.basicInfo.own_delivrey) {
            this.my_own = true,
            this.thired_party = false;
            this.ownDelivreyPartner = this.basicInfo.deliver;
          } else {
            this.thired_party = true;
            this.my_own = false;
            this.selecteddeliverypartner= this.basicInfo.deliver;
          }
        }
      } catch (error) {
        throw error;
      }
    },
    enableThiredParty: function(val) {
      if (val === true) {
        this.my_own = false;
      }
    },
    enableMyOwn: async function(val) {
      if (val === true) {
        this.thired_party = false;
        if(this.deliverDetails.length > 0) {
          if (this.deliverDetails[0].dashboard_status <= 2) {
            this.ownDelivreyPartner = this.deliverDetails[0]._id;
            this.deliverySubmit();
            localStorage.setItem("deliver_token", this.deliverDetails[0].token);
            localStorage.setItem("deliver_type", "deliver");
            localStorage.setItem("login_deliver", this.deliverDetails[0]._id);
            localStorage.setItem("deliver_data", JSON.stringify(this.deliverDetails[0]));
            this.$store.dispatch("doLogindeliver");
            let routeData = this.$router.resolve("/deliver-profile");
            window.open(routeData.href, '_blank');
          } else {
            this.ownDelivreyPartner = this.deliverDetails[0]._id;
            this.deliverySubmit();
          }
        }
      }
    },
    fetchDeliveryPartners: async function() {
      let response = await this.getOwnDeliveryPartners(1);
      if (response.length !== 0) {
        this.deliverpartners = response;
        this.selecteddeliverypartner = this.deliverpartners[0]._id
      }
    },
    getDelivaryOwn: async function() {
      try {
        let response = await this.getOwnDeliveryPartners(2);
        this.deliverDetails = response;
        if (response.length === 0) {
            this.is_delivary = false;
        } else {
          if (response[0].dashboard_status <= 2) {
            this.is_delivary = true;
            this.ownDelivreyPartner = response[0]._id;
            if (response[0].active_status === 2 && response[0].admin_status === 2) {
              this.delivaryActiveStatus = "Reject"
            } else if(response[0].active_status === 1 && response[0].admin_status === 2) {
              this.delivaryActiveStatus = "Pending"
            } else if(response[0].active_status === 2 && response[0].admin_status === 1) {
              this.delivaryActiveStatus = "Deactive"
            } else {
              this.delivaryActiveStatus = "Active"
            }
            if (this.my_own) {
              this.ownDelivreyPartner = this.deliverDetails[0]._id;
              this.deliverySubmit();
              localStorage.setItem("deliver_token", this.deliverDetails[0].token);
              localStorage.setItem("deliver_type", "deliver");
              localStorage.setItem("login_deliver", this.deliverDetails[0]._id);
              localStorage.setItem("deliver_data", JSON.stringify(this.deliverDetails[0]));
              this.$store.dispatch("doLogindeliver");
              let routeData = this.$router.resolve("/deliver-profile");
              window.open(routeData.href, '_blank');
            }
          } else {
            this.is_delivary = true;
            this.ownDelivreyPartner = response[0]._id;
            if (response[0].active_status === 2 && response[0].admin_status === 2) {
              this.delivaryActiveStatus = "Reject"
            } else if(response[0].active_status === 1 && response[0].admin_status === 2) {
              this.delivaryActiveStatus = "Pending"
            } else if(response[0].active_status === 2 && response[0].admin_status === 1) {
              this.delivaryActiveStatus = "Deactive"
            } else {
              this.delivaryActiveStatus = "Active"
            }
          }
        }
      } catch(error) {
        throw error;
      }
    },
    createDelivary: async function() {
      if (this.agree_terms_Delivary) {
        try {
          let response = await this.createOwnDeliveryPartner(2);
          this.ownDelivreyPartner = response._id;
          localStorage.setItem("deliver_token", response.token);
          localStorage.setItem("deliver_type", "deliver");
          localStorage.setItem("login_deliver", response._id);
          localStorage.setItem("deliver_data", JSON.stringify(response));
          this.$store.dispatch("doLogindeliver");
          let routeData = this.$router.resolve("/deliver-profile");
          window.open(routeData.href, '_blank');
           this.$swal.fire({
            position: "top-right",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          this.$swal.fire({
            position: "top-right",
            type: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    deliverySubmit: async function() {
      try {
        let obj = {}
        if (this.my_own) {
          obj.deliver = this.ownDelivreyPartner;
          obj.own_delivrey = true;
        }
        if (this.thired_party) {
          obj.deliver = this.selecteddeliverypartner;
          obj.own_delivrey = false;
        }
        let res = await this.putDeliveryPatner(obj);
        this.$swal.fire({
          position: "top-right",
          type: "success",
          title: "successfully updated",
          showConfirmButton: false,
          timer: 1500,
        });
        if (!this.basicInfo.deliver) {
          this.postRequiredStage(7)
        }
        this.getPersanalInfo();
      } catch (error) {
        this.$swal.fire({
          position: "top-right",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getPersanalInfo();
      }
    },
    postRequiredStage: async function(stage) {
      let obj = {
        complated_status: stage,
      };

      let response = await this.postDashboardStage(obj);

    },
    visitDelivary: function() {
      let routeData = this.$router.resolve("/deliver-login");
      window.open(routeData.href, '_blank');
    },
  }
}
</script>
<style scoped>
.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.merchentitle .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchprof-sbtn:hover {
  color: #000000;
}

#hubTable {
  width: 100%;
}

#hubTable tr td {
  padding: 5px;
}
</style>