<template>
    <div class="merchonlineaddr">
        <p class="lead" style="font-size: 17px;">{{this.basicInfo.account_type === 1 ? "Personal Address" : "Business Address"}}</p>
        <div class="row">
            <div class="col-sm-12">
            <div  v-for="(item, index) in addressArray" :key="index">
              <div>
                 <p v-if="index === 1" class="lead" style="font-size: 17px;">Billing Address</p>
                <div class="row" style="margin-bottom: 3px;">
                    <div class="col-sm-5">
                        <div class="form-group required">
                        <label class="control-label">Country</label>
                            <select class="form-control select-arrow" @change="changeCountries($event.target.value, index, true)" v-model="item.country" :disabled="index == 1 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option v-for="(item, val) in countries" :key="val" :value="item._id">{{ item.country_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group required">
                        <label class="control-label">Province</label>
                            <select class="form-control select-arrow" @change="changeProvince($event.target.value, index, true)" v-model="item.province" :disabled="index == 1 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in provinceArray[index]" :key="val" :value="item._id">{{ item.province_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                <div class="row" style="margin-bottom: 3px;">
                    <div class="col-sm-5">
                        <div class="form-group required">
                        <label class="control-label">District / Area</label>
                           <select class="form-control select-arrow" @change="changeRegion($event.target.value, index, true)" v-model="item.region" :disabled="index == 1 && billingsame">
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in regionArray[index]" :key="val" :value="item._id">{{ item.region_name}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group required">
                        <label class="control-label">City</label>
                            <select class="form-control select-arrow" @change="changeCity($event.target.value, index, true)" v-model="item.city" :disabled="index == 1 && billingsame">
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in cityArray[index]" :key="val" :value="item._id" >{{ item.city_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                <div class="row" style="margin-bottom: -10px;">
                    <div class="col-sm-5">
                        <label class="control-label " for="input-password">Post Code</label>
                        <input disabled type="text" class="form-control" v-model="item.postal_code" />
                    </div>
                </div>
                <br/>
                <div class="row" style="margin-bottom: 16px;">
                    <div class="col-sm-10 form-group required">
                        <label class="control-label" for="input-password">Address Line 1</label>
                        <input type="text" class="form-control" placeholder="" v-model="item.address_line_1" :disabled="index == 1 && billingsame"/>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                 <div class="row" style="margin-bottom: 16px;">
                    <div class="col-sm-10 form-group required">
                        <label class="control-label" for="input-password">Address Line 2</label>
                        <input type="text" class="form-control" placeholder="" v-model="item.address_line_2" :disabled="index == 1 && billingsame"/>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                <!-- <div class="row" v-if="index === 0">
                    <div class="col-sm-3">                            
                        <label>
                          <input type="checkbox" :value="isCheck" name="newsletter" checked="checked" v-model="billingsame" @change="clickButton(item.is_biling_address, index)"> Billing to this address
                        </label>
                    </div>
                    <div class="col-sm-3 text-right" v-if="index === valIndex && index <= 0">
                    </div>
                    <div class="col-sm-6">
                    </div>     
                </div> -->
                <br/>
              </div>
            </div>
            <div v-if="!isKycVeryfy" class="row">
              <div class="col-sm-12">
                <div class="form-group text-right">
                  <button class="btn merchprof-sbtn" :disabled="!formIsValid" @click="submitProfileAddress" >Submit</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/merchant/APIProfile";
export default {
  mixins: [mixinCommon, mixinProfile],
  data: function () {
    return {
      billingsame: true,
      valIndex: 0,
      countries: [],
      province: [],
      region: [],
      city: [],
      basicInfo:{},
      provinceArray:[],
      regionArray:[],
      cityArray:[],
      addressArray: [{
        address: "",
        country: "5d8b9e5dfb195816aaea61b3",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        primary: false,
        address_line_1: "",
        address_line_2: ""
      }
      // {
      //   address: "",
      //   country: "",
      //   province: "",
      //   region: "",
      //   city: "",
      //   postal_code: "",
      //   is_biling_address: true,
      //   primary: false,
      //   address_line_1: "",
      //   address_line_2: ""
      // }
      ],
      address:{
        address: "",
        country: "5d8b9e5dfb195816aaea61b3",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        primary: false,
        address_line_1: "",
        address_line_2: ""
      },
      isCheck: true,
      check: false,
      check1: false,
      multiAddress: true,
      showAddresslvl: [
        true,
        false,
      ]
    }
  },
  computed: {
    formIsValid() {
      return (
        this.addressArray[0].address_line_1 &&
        // this.addressArray[0].address_line_2 &&
        this.addressArray[0].city &&
        this.addressArray[0].country &&
        this.addressArray[0].province &&
        this.addressArray[0].region
      );
    },
    isKycVeryfy: function () {
      if(this.basicInfo.kyc_lists[0]){
        if (this.basicInfo.kyc_lists[0].address_image_approve_status === "approved" || this.basicInfo.kyc_lists[0].address_image_approve_status === "resubmit") {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  created(){
    this.getCountryDetails();
    this.getPersanalInfo();
  },
  methods: {
    changeCity: async function(event, index, useraction = false) {
      let pCode = this.city.filter(obj => {
        return obj._id === event;
      });
      this.addressArray[index].postal_code = pCode[0].postal_code;
    },
    funPickup() {
      this.check = this.check === false ? true : false;
    },
    funReturn() {
      this.check1 = this.check1 === false ? true : false;
    },
    addNewClick: function() {
    if (this.addressArray.length <= 1) {
        if (this.valIndex === 0) {
        this.addressArray[0].primary = true;
      }
      this.valIndex = this.valIndex + 1;
      this.addressArray.push( this.address );
    }
    },
    getCountryDetails: async function() {
      try {
          let response = await this.getCountry(); 
          this.countries = response;
          let id = "5d8b9e5dfb195816aaea61b3";
          this.changeCountries(id, 0, true)
              
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })      
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
          let response = await this.getProvince(event); 
          this.province = response;

          this.$set(this.provinceArray, index, response);
          this.regionArray[index] = [];
          this.cityArray[index] = [];

          // this.provinceArray[index] = response;
        
          // // RESETING 
           if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].province = "";
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
           } 
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
          let response = await this.getRegion(event); 
          this.region = response;

          this.$set(this.regionArray, index, response);

           // RESETING 
          this.cityArray[index] = [];
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
          }
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
          let response = await this.getCity(event); 
          this.city = response;

          this.$set(this.cityArray, index, response);  
          
          // RESETING  
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].city = ""; 
          }  
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    DeleteClick: async function(index, itemid){
      this.addressArray.splice(index, 1);
      this.addressArray[0].is_biling_address = true;   
      if(itemid){
        await this.deleteProfileAddress(itemid);
      }   
    },
    submitProfileAddress: async function() {

        // this.copyBiilingAddress();
        let obj = {};
        obj.addresses = this.addressArray;

        // console.log("pass obj")
        // console.log(obj.addresses[0])
        if (
          obj.addresses[0].address_line_1 === "" ||
          // obj.addresses[0].address_line_2 === "" ||
          obj.addresses[0].city === "" ||
          obj.addresses[0].country === "" ||
          obj.addresses[0].province === "" ||
          obj.addresses[0].region === ""
        ) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Complete Address Information!",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
        // if(this.addressArray[0].address_1 === "" || this.addressArray[1].address_1 === "" ){
        //   this.$swal.fire({
        //           position: 'center',
        //           type: 'error',
        //           title: 'Fill All Required Fields',
        //           showConfirmButton: false,
        //           timer: 1500
        //         })    
        //   return;
        // }

        // if(this.addressArray[0].country === "" || this.addressArray[1].country === "" ){
        //   this.$swal.fire({
        //           position: 'center',
        //           type: 'error',
        //           title: 'Fill All Required Fields',
        //           showConfirmButton: false,
        //           timer: 1500
        //         })    
        //   return;
        // }

        // if(this.addressArray[0].province === "" || this.addressArray[1].province === "" ){
        //   this.$swal.fire({
        //           position: 'center',
        //           type: 'error',
        //           title: 'Fill All Required Fields',
        //           showConfirmButton: false,
        //           timer: 1500
        //         })    
        //   return;
        // }

        // if(this.addressArray[0].region === "" || this.addressArray[1].region === "" ){
        //   this.$swal.fire({
        //           position: 'center',
        //           type: 'error',
        //           title: 'Fill All Required Fields',
        //           showConfirmButton: false,
        //           timer: 1500
        //         })    
        //   return;
        // }

        // if(this.addressArray[0].city === "" || this.addressArray[1].city === "" ){
        //   this.$swal.fire({
        //           position: 'center',
        //           type: 'error',
        //           title: 'Fill All Required Fields',
        //           showConfirmButton: false,
        //           timer: 1500
        //         })    
        //   return;
        // }


        obj.addresses = this.addressArray;
        try {
          let response = await this.putProfileAddress(obj);
                        //  await this.$parent.postRequiredStage(2);
          if(!this.addressArray[0]._id){
            await this.$parent.postRequiredStage(2);
          } else {
            if(this.basicInfo.kyc_lists[0].address_image_approve_status === "rejected") {
              let self = this;
              this.$swal.fire({
                position: 'center',
                type: 'info',
                title: 'Check your KYC address Proof information and click on address Proof Submit for approve.',
                showConfirmButton: true,
                }).then(function() {               
                  self.$parent.ActiveKycTab();
              })
            }
          }
          // await this.$parent.checkRequiredStage();
          // self.$parent.checkRequiredStage();
          // this.$swal.fire({
          //         position: 'center',
          //         type: 'success',
          //         title: 'Submit success!',
          //         showConfirmButton: false,
          //         timer: 1500
          //         }).then(function() {
          //         // self.$parent.checkRequiredStage();                
          //         // self.$parent.activateTab(2);
          //       })
               this.getPersanalInfo();  
               await this.$parent.checkRequiredStage();
        } catch (error) {
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Submit error!',
                  showConfirmButton: false,
                  timer: 1500
                })      
        }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.basicInfo = response;
        this.$parent.checkRequiredStage();
        // console.log
        if (response.addresses.length === 0) {
            this.addressArray = [{
                address: "",
                country: "5d8b9e5dfb195816aaea61b3",
                province: "",
                region: "",
                city: "",
                postal_code: "",
                is_biling_address: true,
                primary: false
            }
            // {
            //     address: "",
            //     country: "",
            //     province: "",
            //     region: "",
            //     city: "",
            //     postal_code: "",
            //     is_biling_address: true,
            //     primary: false
            // }
            ];
            await this.changeCountries(this.countries[0]._id, 0);
        } else {
             for (let index = 0; index < response.addresses.length; index++) {
              await this.changeCountries(this.countries[0]._id, index);
              await this.changeProvince(response.addresses[index].province, index);
              await this.changeRegion(response.addresses[index].region, index);
              this.addressArray = response.addresses; 
            }            
            this.addressArray = response.addresses; 

            // if((this.addressArray[0].address === this.addressArray[1].address) 
            //   && (this.addressArray[0].country === this.addressArray[1].country) 
            //   && (this.addressArray[0].province === this.addressArray[1].province)   
            //   && (this.addressArray[0].region === this.addressArray[1].region) 
            //   && (this.addressArray[0].city === this.addressArray[1].city) 
            //   && (this.addressArray[0].postal_code === this.addressArray[1].postal_code) ){
            //     this.billingsame = true;
            //   } else {
            //     this.billingsame = false;
            //   } 
        }

      } catch (error) {
        throw error;
      }
    },
    clickButton: function(val, tickindex) {
      if(this.addressArray[tickindex].is_biling_address === false){
        if(this.addressArray.length < 2){
          this.addNewClick();
        }
      }      
      for (let index = 0; index < this.addressArray.length; index++) {
        if(val === false){
          if(tickindex != index){
            this.addressArray[index].is_biling_address = true;
          }
 
        } else {
          if(tickindex != index){
            this.addressArray[index].is_biling_address = false;
          }
        }         
      }

      // if(this.billingsame === true){
      //   this.provinceArray[1] = JSON.parse(JSON.stringify(this.provinceArray[0]));
      //   this.regionArray[1] = JSON.parse(JSON.stringify(this.regionArray[0]));
      //   this.cityArray[1] = JSON.parse(JSON.stringify(this.cityArray[0]));
      //   Object.assign(this.addressArray[1], this.addressArray[0])       
      // } else {
      //   if(clicked) {
      //     Object.assign(this.addressArray[1], this.address) 
      //     this.provinceArray[1] = [];
      //     this.regionArray[1] = [];
      //     this.cityArray[1] = [];
      //   }
      // }
      
      
        if (this.billingsame === true) {
          this.billingsame = true; 
          this.$set(this.showAddresslvl, 1, false);
          this.provinceArray[1] = JSON.parse(JSON.stringify(this.provinceArray[0]));
          this.regionArray[1] = JSON.parse(JSON.stringify(this.regionArray[0]));
          this.cityArray[1] = JSON.parse(JSON.stringify(this.cityArray[0]));
          
          let tempid = '';

          if(this.addressArray[1]._id){
            tempid = this.addressArray[1]._id;
          }

          Object.assign(this.addressArray[1], this.addressArray[0])   
          
          if(tempid != ''){
            this.addressArray[1]._id = tempid;
          }
          
          this.addressArray[1].primary = false;
          this.addressArray[0].is_biling_address = false;
          this.addressArray[1].is_biling_address = true;
          
            this.isCheck = false
            this.multiAddress = false
        } else {
            this.billingsame = false;
            this.$set(this.showAddresslvl, 1, true);
            Object.assign(this.addressArray[1], this.address) 
            
            this.provinceArray[1] = [];
            this.regionArray[1] = [];
            this.cityArray[1] = [];
            this.isCheck = true
            this.multiAddress = true
        } 

    },
    copyBiilingAddress(){
    
        if (this.billingsame === true) {
            this.billingsame = true;
            this.provinceArray[1] = JSON.parse(JSON.stringify(this.provinceArray[0]));
            this.regionArray[1] = JSON.parse(JSON.stringify(this.regionArray[0]));
            this.cityArray[1] = JSON.parse(JSON.stringify(this.cityArray[0]));

            let tempid = '';

            if(this.addressArray[1] && this.addressArray[1]._id){
              tempid = this.addressArray[1]._id;
            }
  
  
            Object.assign(this.addressArray[1], this.addressArray[0])   
         
            if(tempid != ''){
              this.addressArray[1]._id = tempid;
            }
           
            this.addressArray[1].primary = false;
            this.addressArray[0].is_biling_address = false;
            this.addressArray[1].is_biling_address = true;
     
              this.isCheck = false;
              this.multiAddress = false;
        } 
    }
  }
}
</script>
<style>
.merchonlineaddr .merchprof-sbtn{
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchonlineaddr .merchprof-sbtn:hover{
  color: #000000;
}
</style>
