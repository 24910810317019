<template>
  <div class="bankdetailtrans">
    <p class="lead" style="font-size: 18px;">Bank Details</p>
    <div class="row">       
      <div class="col-sm-12">
          
        <!-- <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="input-payment-country" class="control-label">Type</label>
              <select class="form-control select-arrow" id="input-payment-country" name="country_id" @change="changeType($event.target.value)" v-model="saveImgObject.kyc_type" >
                <option value="-1"> --- Please Select --- </option>
                <option value="1">NIC Number</option>
                <option value="2">Passport Number</option>
              </select>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col-sm-4">
            <div class="form-group required">
              <label v-if="this.fullInfo.account_type === 1" for="input-middlename" class="control-label">Bank Account Holder Name</label>
              <label v-if="this.fullInfo.account_type === 2" for="input-middlename" class="control-label">Business Bank Account Name</label>
              <input type="text" required class="form-control" placeholder="Name" v-model="bankAccount.account_name" @keyup="validateBankAccountName(bankAccount.account_name)" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)">
            </div>
          </div>
        </div>
          
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group required">
              <label for="input-middlename" class="control-label">Account Number</label>
              <input type="text" required class="form-control" @input="valAccountNumber(bankAccount.account_number)" placeholder="Account Number" v-model="bankAccount.account_number">
            </div>
          </div>
        </div>
          
        <div class="row">
          <div class="col-sm-4">
            <!-- <div class="form-group">
              <label for="input-middlename" class="control-label">Bank</label>
              <input type="text" required class="form-control" placeholder="Bank" v-model="bankAccount.bank">
            </div> -->
            <div class="form-group required">
            <label class="control-label">Bank</label>
              <select class="form-control select-arrow"  @change="changeBank($event.target.value)" v-model="bankAccount.bank">
                <option value=""> --- Please Select --- </option>
                <option v-for="(item, val) in banks" :key="val" :value="item._id">{{ item.name}}</option>
              </select>
            </div>

          </div>
        </div>
          
        <div class="row">
          <div class="col-sm-4">
            <!-- <div class="form-group">
              <label for="input-middlename" class="control-label">Branch</label>
              <input type="text" required class="form-control" placeholder="Branch" v-model="bankAccount.branch">
            </div> -->
            <div class="form-group required">
            <label class="control-label">Branch</label>
              <select class="form-control select-arrow"  @change="changeBranch($event.target.value)" v-model="bankAccount.branch">
                <option value=""> --- Please Select --- </option>
                <option v-for="(item, val) in branch" :key="val" :value="item._id">{{ item.name}}</option>
              </select>
            </div>
          </div>
        </div>
        
        <p class="lead">Bank Account Proof</p>

        <div class="row">
          <div class="col-sm-4">
            <p style="font-size: 16px; color: red;">* Please upload your images</p>
            <div style="margin-left: 10px;">
              <p style="color: red">* All four corners to be visible.</p>
              <p style="color: red">* Pass book image/digital bank official statement/account statement to be uploaded.</p>
              <p style="color: red">* We accept .Jpg / .PNG & PDF formats only, Must be below 3MB.</p>
            </div>
          </div>
        </div>

        <div class="row">
        <div class="col-sm-12">
            <h5><b>{{ Object.keys(fullInfo).length != 0 ? fullInfo.kyc_lists[0].bank_approve_status :" " }}</b></h5>
            <h5 v-if="showRejectReason"><b>{{ Object.keys(fullInfo).length != 0 ? 'Reject reason: -' + fullInfo.kyc_lists[0].bank_reject_reason.reason :" " }}</b></h5>
            <div v-if="showRejectReason && fullInfo.kyc_lists && fullInfo.kyc_lists[0].bank_reject_reason_description" v-html="fullInfo.kyc_lists[0].bank_reject_reason_description"></div>
            <img v-if="!isPdf" style="width: auto; height: 300px;" slot="image" class="card-img-top" :src="contactImage1" alt="Card image cap">
            <iframe
              v-if="isPdf"
              :src="contactImage1"
              frameBorder="0"
              scrolling="none"
              height="325px"
              width="100%"
            ></iframe>
            <br>
            <br>
            <button class="btn btn-sm merchprof-sbtn" v-if="showComponent" @click="pickKycProf()">Upload</button>
            <input 
              type="file" 
              class="inputfile"
              @change="getFile1"
              id="embedpollfileinput"
              ref="upprofpicturemer1"
              style="display: none;">

        </div>
      </div>

        <div v-if="showComponent" class="row">
          <div class="col-sm-12 footerClass" >
            <div class="form-group text-right">
              <button v-if="!isLoading" class="btn merchprof-sbtn" @click="haddleAccount()" :disabled="!formIsValid" >Submit</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="isLoading">
          <div class="col-sm-12 footerClass2">
            <div class="text-right">
              <div style="display: flex; width: 140px; justify-content: end; flex-direction: column; align-items: unset; float: right;">
                <button
                  disabled
                  style="color: white;"
                  class="btn merchprof-sbtn"
                >Loading...
                </button>
                <progress-bar
                  style="margin-top: 10px;"
                  :options="options"
                  :value="loadingprogress"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinProfile from "../../../mixins/merchant/APIProfile";
export default {
  mixins: [mixinProfile],
  data: function () {
    return {
        loadingprogress: 0,
        fullInfo: {},
        bankAccount: {},
        banks: [],
        branch: [],
        fileUploadFormData: new FormData(),
        image1: "",
        img1: "",
        isPdf: false,
        isLoading: false,
        options: {
          text: {
            color: '#FFFFFF',
            shadowEnable: true,
            shadowColor: '#000000',
            fontSize: 14,
            fontFamily: 'Helvetica',
            dynamicPosition: false,
            hideText: false
          },
          progress: {
            color: '#2dbd2d',
            backgroundColor: '#C0C0C0'
          },
          layout: {
            height: 30,
            width: 140,
            verticalTextAlign: 61,
            horizontalTextAlign: 43,
            zeroOffset: 0,
            strokeWidth: 30,
            progressPadding: 0,
            type: 'line'
          }
        }
    }
   },
   computed: {
    formIsValid() {
      return (
        this.bankAccount.account_name &&
        this.bankAccount.account_number &&
        this.bankAccount.bank &&
        this.bankAccount.branch &&
        this.image1
      );
    },
    contactImage1: function() {
      if (this.image1) {
        return this.image1;
      } else {
        return "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629963841/cochchi/mercahnt/1629963840267.webp";
      }
    },
    showComponent: function() {
      if (Object.keys(this.fullInfo).length != 0) {
        if (this.fullInfo.kyc_lists[0].bank_approve_status !== "approved") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    showRejectReason: function() {
      if (Object.keys(this.fullInfo).length != 0) {
        if (this.fullInfo.kyc_lists[0].bank_approve_status === "rejected") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
   },
   created() {
    this.getPersanalInfo();
    this.getBankList();
   },
   methods: {
    pickKycProf: function() {
      this.$refs.upprofpicturemer1.click();
    },
    valAccountNumber(number) {
      number = number.replace(/[^0-9]/g, "");
      this.bankAccount.account_number = number;
    },
    getFile1: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf = true;
      } 
      else {
        this.isPdf = false;
      }
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image1 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image1 = tgt.files[0];
      this.img1 = eventf.target.files[0]
      // this.fullInfo.kyc_lists[0].bank_approve_status = "pending"
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.$parent.checkRequiredStage();
        this.fullInfo = response;
        this.bankAccount = response.kyc_lists[0];
        this.image1 = response.kyc_lists[0].bank_image;
        if (this.image1.split('.').pop().toLowerCase() === "pdf") {
            this.isPdf = true;
        } 
        else {
          this.isPdf = false;
        }
        this.changeBank(response.kyc_lists[0].bank);
      } catch (error) {
        console.log(error);
      }
    },
    getBankList: async function() {
      try {
        let response = await this.getBankListInfo();
        this.banks = response;
      } catch (error) {
        console.log(error);
      }
    },
    changeBank: async function(event) {
      try {
        this.bankAccount.bank = event;
        let response = await this.getBranchListInfo(event);
        this.branch = response;
      } catch (error) {
        console.log(error);
      }
    },
    changeBranch: function(event) {
      this.bankAccount.branch = event;
    },
    validateBankAccountName(bName) {
      if (str !== "") {
        if (/^[A-Za-z]+$/.test(bName)) {
          this.bankAccount.account_name  = bName;
        } else {
          this.bankAccount.account_name  = bName.slice(0, -1);
        }
      }
    },
    haddleAccount: async function() {
        try {
            this.isLoading = true;
            this.loadingprogress = 15
            let bankKycType = 4;
            await this.fileUploadFormData.append("kyc_type", bankKycType);
            await this.fileUploadFormData.set("account_name", this.bankAccount.account_name);
            await this.fileUploadFormData.set("account_number", this.bankAccount.account_number);
            await this.fileUploadFormData.append("bank", this.bankAccount.bank);
            await this.fileUploadFormData.append("branch", this.bankAccount.branch);
            if (this.img1 !== "") {
              await this.fileUploadFormData.append("image", this.img1);
            } else {
              await this.fileUploadFormData.set("image", this.image1);
            }
            this.loadingprogress = 30
            let formData = await this.fileUploadFormData;
            await this.putProfileFormBacicInfo(formData);

            this.loadingprogress = 100
            this.checkKYCAprovation();
            this.getPersanalInfo();
            this.isLoading = false;
            this.fileUploadFormData = new FormData();
            this.$swal.fire({
            position: 'center',
            type: 'success',
            title: 'success submit account details',
            showConfirmButton: false,
            timer: 1500
            });

        } catch (error) {
            this.isLoading = false;
            this.fileUploadFormData = new FormData();
            this.$swal.fire({
              position: "center",
              type: "error",
              title: error.message,
              showConfirmButton: false,
              timer: 1500,
              });
        }
    },
    checkKYCAprovation: async function() {
      try {
        let response = await this.getProfileBacicInfo();
         if (response.kyc_lists.length > 0 && response.kyc_lists[0].bank_approve_status === "pending") {
          await this.$parent.postRequiredStage(4);
          await this.$parent.checkRequiredStage();
         }
      } catch (error) {
        console.log(error);
      }
    }
   }
}
</script>
<style>
.bankdetailtrans .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.bankdetailtrans .merchprof-sbtn:hover {
  color: #000000;
}
@media (min-width: 768px) {
  .footerClass {
    margin-top: 26vh;
  }
}
@media (min-width: 1280px) {
  .footerClass {
    margin-top: 22vh;
  }
}
@media (min-width: 1440px) {
  .footerClass {
    margin-top: 19vh;
  }
}
@media (min-width: 1800px) {
  .footerClass {
    margin-top: 19vh;
  }
}
@media (min-width: 1858px) {
  .footerClass {
    margin-top: 18vh;
  }
}
@media (min-width: 1880px) {
  .footerClass {
    margin-top: 9vh;
  }
}
@media (min-width: 2800px) {
  .footerClass {
    margin-top: 9vh;
  }
}
@media (min-width: 2880px) {
  .footerClass {
    margin-top: 10vh;
  }
}
</style>

