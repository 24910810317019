<template>
  <div v-if="basicInfo" class="merchprofpersonal">
    <p class="lead" style="color: #5779ae; font-size: 17px;">
      Hello,
      <strong>{{ basicInfo.first_name }} {{ basicInfo.last_name }}</strong> -
      Welcome.
    </p>
    <div class="row">
      <div id="merchantImg" class="col-lg-3 col-md-12 col-sm-12 text-center">
        <img v-if="!isPdf" slot="image" class="card-img-top" :src="contactImage" alt="Card image cap" />
        <iframe
          v-if="isPdf"
          :src="contactImage"
          frameBorder="0"
          scrolling="none"
          height="300px"
          width="100%"
        ></iframe>
        <br />
        <br />
        
        <!-- <input type="file" class="btn btn-default mt-2 pt-1" @change="getFile" >Update Image</input> -->
        <button class="btn btn-sm merchprof-sbtn" @click="pickProfPicture()">Upload</button>
        <input
          type="file"
          class="inputfile"
          id="embedpollfileinput"
          @change="getFile"
          ref="upprofpicturemer"
          style="display: none;"
        />
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-md-4 col-sm-8">
            <div class="form-group form-inline">
              <label for="input-middlename" class="control-label">Account type</label>
              <br />
              <input
                type="text"
                class="form-control"
                style="width: 100%;"
                disabled
                placeholder="Account type"
                v-model="accType"
              />
              <button
                v-if="accType === 'Personal'"
                @click="switchBusi()"
                class="btn btn-primary form-control"
              >Switch to Business</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group">
              <label for="input-middlename" class="control-label">First Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="First Name"
                v-model="basicInfo.first_name"
                :disabled="isKycVeryfy"
                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
              />
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group">
              <label for="input-middlename" class="control-label">Middle Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Middle Name"
                v-model="basicInfo.middle_name"
                :disabled="isKycVeryfy"
              />
            </div>
          </div> -->
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group">
              <label for="input-middlename" class="control-label">Last Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Last Name"
                v-model="basicInfo.last_name"
                :disabled="isKycVeryfy"
                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label">Email</label>
              <input
                type="text"
                class="form-control"
                placeholder="Email"
                v-model="basicInfo.email"
                disabled
              />
              <div style="padding-top: 7px">
                <div :style="emailVerified">
                  <span style="font-size: 10px; color: green">
                    <i style="color: green" class="fa fa-check-circle"></i>
                    Email verified
                  </span>
                </div>
                <div :style="emailNotVerified">
                  <span style="font-size: 10px; color: red; margin-right: 15px">
                    <i style="color: red" class="fa fa-times-circle"></i> Email
                    not verified
                  </span>
                  <button
                    @click="verifyEmail()"
                    class="btn btn-sm buyerprof-sbtn"
                  >
                    Resend Email
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="mobileNumcol" class="col-lg-4 col-md-6 col-sm-12">
            <div class="input-group">
              <label for="input-middlename" class="control-label">Mobile No</label>
              <VuePhoneNumberInput
                ref="mobilecom"
                valid-color="green"
                error-color="red"
                @input="validatePhone(basicInfo.mobile)"
                @update="validateMobile"
                v-model="basicInfo.mobile"
                :only-countries="['LK']"
                :default-country-code="default_country_mobile"
                :translations="{ phoneNumberLabel: '' }"
                no-example
                style="height:30px; cursor:auto;"
                id="mobileInput"
              />
              <div style="padding-top: 7px">
                <div v-if="mobile_verify">
                  <span style="font-size: 10px; color: green">
                    <i style="color: green" class="fa fa-check-circle"></i>
                    Mobile verified
                  </span>
                </div>
                <div v-if="!mobile_verify">
                  <span style="font-size: 10px; color: red; margin-right: 15px">
                    <i style="color: red" class="fa fa-times-circle"></i> Email
                    not verified
                  </span>
                </div>
              </div>
              <!-- <input
                type="text"
                class="form-control"
                placeholder="Mobile No"
                v-model="basicInfo.mobile"
              /> -->
            </div>
          </div>

          <div id="fixedLineNumberInput" class="col-lg-4 col-md-6 col-sm-12">
            <div class="input-group">
              <label
                for="input-middlename"
                id="fixedNumTxt"
                class="control-label"
              >Fixed line number (optional)</label>
              <!-- <VuePhoneNumberInput
                ref="mobilecom1"
                valid-color="green"
                @input="validatePhone1(basicInfo.fixed_line_number)"
                @update="validateFix"
                v-model="basicInfo.fixed_line_number"
                :default-country-code="default_country_fixed"
                :translations="{ phoneNumberLabel: '' }"
                no-example
                style="height:30px; cursor:auto;"
                id="mobileInput1"
              /> -->
              <input
                  class="form-control fixedline-number-input" 
                  @input="fixedLineNumber(basicInfo.fixed_line_number)" 
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  
                  maxlength="10" 
                  v-model="basicInfo.fixed_line_number" 
                  type="tel"
                  :style="changeBorderFixedLine"
                  pattern="[0-9]{10}">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 footerClass2">
            <div class="form-group text-right">
              <button
                class="btn merchprof-sbtn"
                :disabled="!hmobileValid || !fixedLineValid"
                @click="submitProfile"
              >Submit
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="row" v-if="isLoading">
          <div class="col-sm-12 footerClass2">
            <div class="text-right">
              <div style="display: flex; width: 140px; justify-content: end; flex-direction: column; align-items: unset; float: right;">
                <button
                  v-if="isLoading"
                  class="btn merchprof-sbtn"
                >Loading...
                </button>
                <progress-bar
                  style="margin-top: 10px;"
                  :options="options"
                  :value="loadingprogress"
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <mobileVerifyModal
      @close="close"
      :merchantId="merchantId"
      :mobileForPropval="mobileForPropval"
      :userType="userType"
    />
  </div>
</template>
<script>
import mixinProfile from "../../../mixins/merchant/APIProfile";
import mobileVerifyModal from "@/components/common/modals/mobileVerifyModal.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  components: {
    VuePhoneNumberInput,
    mobileVerifyModal,
  },
  mixins: [mixinProfile],
  data: function() {
    return {
      loading: true,
      loadingprogress:0,
      accType: "",
      disableCon: true,
      userType: "",
      merchantId: "",
      mobileForPropval: "",
      basicInfo: {},
      fileUploadFormData: new FormData(),
      image: "",
      mobile: "",
      isPdf: false,
      fixed_line_number: "",
      hmobileValid: true,
      countryCodeMoblie:"",
      countryCodeFix:"",
      dial_code:"",
      fix_dial_code:"",
      default_country_fixed:"LK",
      default_country_mobile:"LK",
       emailVerified: {
        display: "none",
      },
      emailNotVerified: {
        display: "none",
      },
      changeBorderFixedLine: {
        border: "1px solid #ccc",
      },
      oldNO: "",
      mobile_verify: false,
      fixedLineValid: true,
      isLoading: false,
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#C0C0C0'
        },
        layout: {
          height: 30,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }

    };
  },
  computed: {
    contactImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "assets/image/catalog/about/cl-image-1.jpg";
      }
    },
    isKycVeryfy: function () {
      if (this.basicInfo.verify_status === 2) {
        return true;
      } else {
        return false;
      }
    }
  },
  beforemounted() {
    //  vertically align center the test , flag , +94
    // this.$refs.mobilecom.$el.children[0].children[0].children[0].children[0].style.marginTop =
    //   "5px";
    // this.$refs.mobilecom.$el.children[0].children[0].children[0].style.marginTop =
    //   "-4px";
    // this.$refs.mobilecom.$el.children[0].children[0].children[1].style.paddingTop =
    //   "3px";
    // this.$refs.mobilecom.$el.children[1].children[0].children[0].style.paddingTop =
    //   "3px";

    // this.$refs.mobilecom1.$el.children[0].children[0].children[0].children[0].style.marginTop =
    //   "5px";
    // this.$refs.mobilecom1.$el.children[0].children[0].children[0].style.marginTop =
    //   "-4px";
    // this.$refs.mobilecom1.$el.children[0].children[0].children[1].style.paddingTop =
    //   "3px";
    // this.$refs.mobilecom1.$el.children[1].children[0].children[0].style.paddingTop =
    //   "3px";

    if(this.countryCodeMoblie === "LK") {
      const mobileInputTp = document.querySelector(".input-tel__input");
      mobileInputTp.setAttribute("maxlength", 9);
    }

    this.$nextTick(function() {
      const mobileNumberContainer = document.querySelectorAll(
        ".input-tel__input"
      );
      const countryContainers = document.querySelectorAll(
        ".select-country-container"
      );
      const countrySelectInputs = document.querySelectorAll(
        ".country-selector__input"
      );
      const countrySelectorFlags = document.querySelectorAll(
        ".country-selector__country-flag"
      );
      const countrySelectorArrows = document.querySelectorAll(
        ".country-selector__toggle"
      );

      mobileNumberContainer.forEach(element => {
        element.style.paddingTop = "0px";
        element.style.lineHeight = "40px";
      });

      countryContainers.forEach(element => {
        element.style.width = "90px";
        element.style.minWidth = "90px";
        element.style.flex = "0 0 90px";
      });

      countrySelectInputs.forEach(element => {
        element.style.paddingTop = "0px";
        element.style.lineHeight = "40px";
      });

      countrySelectorFlags.forEach(element => {
        element.style.top = "14px";
      });

      countrySelectorArrows.forEach(element => {
        element.style.top = "calc(50% - 13px)";
      });
    });
  },
  created() {
    this.getPersanalInfo();
    $("#mobileVerifyModalContentCom").modal("hide");
  },
  methods: {
    setMobileNo: function(val) {
      if (val.length <= 9) {
        this.basicInfo.fixed_line_number = val;
      } else {
        this.basicInfo.fixed_line_number = val.substring(0, val.length - 1);
      }
    },
    validateMobile: function(payload) {
      // if (this.mobile.length === 13 && payload.formattedNumber.length === 12 && this.mobile === payload.formattedNumber) {
      //   console.log("true")
      // }
      this.countryCodeMoblie = payload.countryCode;
      this.dial_code = "+" + payload.countryCallingCode;
      this.mobile = payload.formattedNumber;
    },
    validateFirstName(fName) {
      let name = fName;
      if (str !== "") {
        let patton = /^[A-Za-z]+$/
        if (patton.test(name)) {
          this.basicInfo.first_name  = name;
        } else {
          this.basicInfo.first_name  = name.slice(0, -1);
        }
      }
    },
    fixedLineNumber(number) {
      if (number === "") {
        this.changeBorderFixedLine.border = "1px solid #ccc"
        this.fixedLineValid = true;
      } else {
        number = number.replace(/[^0-9]/g, "");
        this.basicInfo.fixed_line_number = number;
        if (/^[0-9]{10}$/.test(number)) {
          this.changeBorderFixedLine.border = "1px solid green";
          this.fixedLineValid = true;
        } else {
          this.changeBorderFixedLine.border = "1px solid red";
          this.fixedLineValid = false;
        }
      }
    },
    validateFix: function(obj) {
      this.countryCodeFix = obj.countryCode;
      this.fix_dial_code = "+" + payload.countryCallingCode;
    },
    validatePhone1(key) {
      if(this.countryCodeFix === "LK") {
        if (key !== null) {
          if (key.length >= 10) {
            this.$refs.mobilecom1.$children[1].$el.firstChild.style.border =
              "1px solid green";
            this.$refs.mobilecom1.$children[1].$el.firstChild.style.boxShadow =
              "green 0px 0px 0px 0px";
            this.$refs.mobilecom1.$children[1].$el.firstChild.style.caretColor =
              "green 0px 0px 0px 0px";
          }
          if (key.length < 10) {
            this.$refs.mobilecom1.$children[1].$el.firstChild.style.border =
              "1px solid red";
            this.$refs.mobilecom1.$children[1].$el.firstChild.style.boxShadow =
              "red 0px 0px 0px 0px";
            this.$refs.mobilecom1.$children[1].$el.firstChild.style.caretColor =
              "red 0px 0px 0px 0px";
          }

          if (!this.basicInfo.hasOwnProperty("fixed_line_number")) {
            this.hmobileValid = false;
            this.hmobileError = "Enter valid phone number";
            return;
          }

          var str = key;
          var res = str.substr(0, 9);
          this.basicInfo.fixed_line_number = res;
          key = res;

          if (key === "0") {
            this.basicInfo.fixed_line_number = "";
          }
          key = key.replace(/^0+/, "");
          key = key.replace(/ /g, "");
          this.basicInfo.fixed_line_number = key;

          if (key.length == 9) {
            if (key == this.basicInfo.fixed_line_number.substring(3)) {
              this.hmobileValid = false;
              this.hmobileError = "Existing Phone Number Detected";
              this.$refs.mobilecom1.$children[1].$el.firstChild.style.borderColor =
                "red";
            } else {
              this.hmobileValid = true;
            }
          } else if (key.length === 10 || key.length < 10) {
            this.hmobileValid = false;
            this.hmobileError = "Enter valid phone number";
          }
          this.validateLengthOfTheMobileNumber(key, 1);
        } else {
          this.disableCon = false;
        }
      } else {
          this.disableCon = false;
      }
    },
    validatePhone(key) {
      if(this.countryCodeMoblie === "LK") {
        if (key !== null) {
          if (key.length >= 9) {
            this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
              "1px solid green";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
              "green 0px 0px 0px 0px";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
              "green 0px 0px 0px 0px";
          }
          if (key.length < 9) {
            this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
              "1px solid red";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
              "red 0px 0px 0px 0px";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
              "red 0px 0px 0px 0px";
          }

          if (!this.basicInfo.hasOwnProperty("mobile")) {
            this.hmobileValid = false;
            this.hmobileError = "Enter valid phone number";
            return;
          }

          var str = key;
          var res = str.substr(0, 9);
          this.basicInfo.mobile = res;
          key = res;

          if (key === "0") {
            this.basicInfo.mobile = "";
          }
          key = key.replace(/^0+/, "");
          key = key.replace(/ /g, "");
          this.basicInfo.mobile = key;

          if (key.length == 9) {
            if (key == this.basicInfo.mobile.substring(3)) {
              this.hmobileValid = false;
              this.hmobileError = "Existing Phone Number Detected";
              this.$refs.mobilecom.$children[1].$el.firstChild.style.borderColor =
                "red";
            } else {
              this.hmobileValid = true;
            }
          } else if (key.length < 9) {
            this.hmobileValid = false;
            this.hmobileError = "Enter valid phone number";
          }
          this.validateLengthOfTheMobileNumber(key, 0);
        }
      } else {
          this.disableCon = false;
      }
    },
    validateLengthOfTheMobileNumber(key1, con) {
      if (con === 1) {
        if (key1 === null) {
          this.disableCon = false;
        } else {
          if (key1.length < 9) {
            this.disableCon = true;
          } else {
            this.disableCon = false;
          }
          if (this.basicInfo.mobile.length < 9) {
            this.disableCon = true;
          }
        }
      } else {
        if (key1 === null) {
          this.disableCon = true;
        } else {
          if (key1.length < 9) {
            this.disableCon = true;
          } else {
            this.disableCon = false;
          }
          if (this.basicInfo.fixed_line_number.length < 9) {
            this.disableCon = true;
          }
        }
      }
    },
    switchBusi() {
      this.$parent.switchToBusi();
    },
    close(val) {
      if (val === true) {
        this.getPersanalInfo();
      }
    },
    viewModalMobileVerify(val) {
      if (val) {
        $("mobileVerifyModalContentCom").modal("show");
        // this.userType = "merchant1";
        this.userType = "merchant_profile";
        this.mobileForPropval = val;
      } else {
        this.$swal.fire({
          position: "center",
          type: "warning",
          title: "Enter the mobile number",
          showConfirmButton: false,
          timer: 150
        });
      }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.basicInfo = response;
        // console.log("this.basicInfo",this.basicInfo);
        this.mobile_verify = response.mobile_verify;
        this.merchantId = this.basicInfo._id;
        this.$parent.checkRequiredStage();

        if (!this.mobile_verify) {
          this.$router.push("merchantprof-mobile-verify");
        }

        this.accType =
          this.basicInfo.account_type === 1 ? "Personal" : "Business";
        if (
          this.basicInfo.fixed_line_number == undefined ||
          this.basicInfo.fixed_line_number == ""
        ) {
          this.basicInfo.fixed_line_number = "";
        }
        else {
          this.basicInfo.fixed_line_number = this.basicInfo.fixed_line_number
        }

        this.validatePhone(this.basicInfo.fixed_line_number)
        
        
        // Added on 08-10-2020
        if (
          this.basicInfo.mobile == undefined ||
          this.basicInfo.mobile == ""
        ) {
          this.basicInfo.mobile = "";
        }
        else {
          this.basicInfo.mobile = this.basicInfo.mobile;
          this.mobile = this.basicInfo.mobile;
          this.oldNO = this.basicInfo.mobile
        }

        
        if (this.basicInfo.image) {
          this.image = this.basicInfo.image;
          if (this.image.split('.').pop().toLowerCase() === "pdf") {
            this.isPdf = true;
          } 
          else {
            this.isPdf = false;
          }
        }
        if (!this.basicInfo.county_code == undefined ||
          !this.basicInfo.county_code == "" ) {
            this.default_country_mobile = this.basicInfo.county_code;
        }
        if (!this.basicInfo.fix_county_code == undefined ||
          !this.basicInfo.fix_county_code == "" ) {
            this.default_country_fixed = this.basicInfo.fix_county_code;
        }

        if (this.basicInfo.email_verify == true) {
          this.emailVerified.display = "block";
          this.emailNotVerified.display = "none";
        } else {
          this.emailNotVerified.display = "block";
          this.emailVerified.display = "none";
        }

      } catch (error) {
        throw error;
      }
    },
    submitProfile: async function() {
      try {
        this.isLoading = true;
        if (this.account_type === "Personal") {
          this.basicInfo.account_type = 1;
        } else if (this.account_type === "Business") {
          this.basicInfo.account_type = 2;
        }

        this.mobile = this.mobile.substring(0,12);
        if (this.oldNO.substring(0,12) != this.mobile.substring(0,12) && this.mobile.length === 12) {
          this.changeMobileNumber(this.mobile);
          delete this.basicInfo.mobile_verify;  
        }

        this.basicInfo.mobile = this.mobile.substring(0,12);
        this.basicInfo.county_code = this.countryCodeMoblie;
        this.basicInfo.dial_code = this.dial_code;
        this.basicInfo.fix_county_code = this.countryCodeFix;
        this.basicInfo.fix_dial_code = this.fix_dial_code;

        delete this.basicInfo.mobile;
        let response = await this.putProfileBacicInfo(this.basicInfo);
        
        if (this.oldNO.substring(0,12) != this.mobile.substring(0,12) && this.mobile.length === 12) {
        } else {
          this.isLoading = false;
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Submit success",
            showConfirmButton: false,
            timer: 1500
          });
        }

        let fullName =
          this.basicInfo.first_name + " " + this.basicInfo.last_name;
        this.$store.commit("updateMerchantName", fullName);
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    },
    changeMobileNumber: async function(no) {
      try {
        let obj = {}
        obj.mobile = no;
        let response = await this.postMobileChange(obj);
        if (response.message) {
          this.$router.push("merchantprof-mobile-verify");
          this.getPersanalInfo();
        }
      } catch (error) {
        await this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        });
        await location.reload();
      }
    },
    pickProfPicture: function() {
      this.$refs.upprofpicturemer.click();
    },
    getFile: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf = true;
      } 
      else {
        this.isPdf = false;
      }
      await this.fileUploadFormData.append("image", eventf.target.files[0]);

      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }

      try {
        let formData = await this.fileUploadFormData;
        let response = await self.putProfileImage(formData);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit Success",
          showConfirmButton: false,
          timer: 1500
        });
        this.fileUploadFormData = new FormData();
        eventf.target.value = "";
      } catch (error) {
        this.fileUploadFormData = new FormData();
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit Error",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    verifyEmail: async function () {
      try {
        let response = await this.merchantEmailVerify();
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Email verfication sent successfully!",
          showConfirmButton: false,
          timer: 1700,
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Email verfication failed,try again!",
          showConfirmButton: false,
          timer: 1700,
        });
      }
    }
  }
};
</script>
<style>
.merchprofpersonal .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchprofpersonal .merchprof-sbtn:hover {
  color: #000000;
}

.select-country-container {
  min-width: 100px !important;
}
/* @media (min-width: 768px) {
  .footerClass2 {
    margin-top: 2vh;
    margin-left: 6vh;
  }
}
@media (min-width: 1280px) {
  .footerClass2 {
    margin-top: 22vh;
    margin-left: 0vh;
  }
} */
#fixedLineNumberInput .fixedline-number-input{
    height: 40px;
}


@media screen and (max-width: 1198px) {
  #fixedNumTxt {
    margin-top: 20px;
  }
}

/* @media screen and (max-width: 1511px) {
  #mobileNumcol {
    width: 290px;
  }
} */

@media screen and (max-width: 1200px) {
  #merchantImg #embedpollfileinput {
    /* margin-left: auto; */
    /* margin-right: auto; */
    padding-left: 50px;
  }
}

@media screen and (max-width: 992px) {
  #fixedNumTxt {
    margin-top: 20px;
  }
  #mobileNumcol {
    width: auto;
  }
}
/* 
@media (min-width: 1440px) {
  .footerClass2 {
    margin-top: 20vh;
    margin-left: 0vh;
  }
}
@media (min-width: 1800px) {
  .footerClass2 {
    margin-top: 10vh;
    margin-left: 0vh;
  }
}
@media (min-width: 1858px) {
  .footerClass2 {
    margin-top: 19vh;
    margin-left: 0vh;
  }
}
@media (min-width: 1880px) {
  .footerClass2 {
    margin-top: 10vh;
    margin-left: 0vh;
  }
}
@media (min-width: 2800px) {
  .footerClass2 {
    margin-top: 10vh;
    margin-left: 0vh;
  }
}
@media (min-width: 2880px) {
  .footerClass2 {
    margin-top: 10vh;
    margin-left: 0vh;
  }
} */

@media screen and (max-width: 320px) {
  #mobileNumcol {
    width: 256px;
  }
}
</style>
<style>
.input-tel.has-value .input-tel__label[data-v-c5bc8062] {
  opacity: 0 !important;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  font-size: 8px;
  display: none !important;
}
</style>
