<template>
    <div>
      <div class="row">
          <div class="col-sm-12">
            <label class="control-label">Address</label>
            <input type="text" class="form-control" v-model="newAddress" disabled/>                
        </div>
      </div>
    </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/merchant/APIProfile";
export default {
  mixins: [mixinCommon, mixinProfile],
  data: function () {
    return {
      billingsame: true,
      valIndex: 0,
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray:[],
      regionArray:[],
      cityArray:[],
      addressArray: [{
        address: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        primary: false
      },
      {
        address: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        primary: false
      }],
      address:{
        address: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        primary: false
      },
      isCheck: true,
      check: false,
      check1: false,
      multiAddress: true,
      newAddress: ""
    }
  },
  created(){
    this.getCountryDetails();
    this.getPersanalInfo();
  },
  methods: {
    funPickup() {
      this.check = this.check === false ? true : false;
    },
    funReturn() {
      this.check1 = this.check1 === false ? true : false;
    },
    addNewClick: function() {
    if (this.addressArray.length <= 1) {
        if (this.valIndex === 0) {
        this.addressArray[0].primary = true;
      }
      this.valIndex = this.valIndex + 1;
      this.addressArray.push( this.address );
    }
    },
    getCountryDetails: async function() {
      try {
          let response = await this.getCountry(); 
          this.countries = response;
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
          let response = await this.getProvince(event); 
          this.province = response;

          this.$set(this.provinceArray, index, response);
          this.regionArray[index] = [];
          this.cityArray[index] = [];

          // this.provinceArray[index] = response;
        
          // // RESETING 
           if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].province = "";
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
           } 
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
          let response = await this.getRegion(event); 
          this.region = response;

          this.$set(this.regionArray, index, response);

           // RESETING 
          this.cityArray[index] = [];
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
          }
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
          let response = await this.getCity(event); 
          this.city = response;

          this.$set(this.cityArray, index, response);  
          
          // RESETING  
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].city = ""; 
          }  
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        // console.log("address")
        // console.log(response)
        if (response.addresses.length === 0) {
            this.addressArray = [{
                address: "",
                country: "",
                province: "",
                region: "",
                city: "",
                postal_code: "",
                is_biling_address: true,
                primary: false
            }
            // {
            //     address: "",
            //     country: "",
            //     province: "",
            //     region: "",
            //     city: "",
            //     postal_code: "",
            //     is_biling_address: true,
            //     primary: false
            // }
            ];
        } else {
             for (let index = 0; index < response.addresses.length; index++) {
              await this.changeCountries(response.addresses[index].country, index);
              await this.changeProvince(response.addresses[index].province, index);
              await this.changeRegion(response.addresses[index].region, index);
              this.addressArray = response.addresses; 
            }            
            this.addressArray = response.addresses;

            // if((this.addressArray[0].address === this.addressArray[1].address) 
            //   && (this.addressArray[0].country === this.addressArray[1].country) 
            //   && (this.addressArray[0].province === this.addressArray[1].province)   
            //   && (this.addressArray[0].region === this.addressArray[1].region) 
            //   && (this.addressArray[0].city === this.addressArray[1].city) 
            //   && (this.addressArray[0].postal_code === this.addressArray[1].postal_code) ){
            //     this.billingsame = true;
            //   } else {
            //     this.billingsame = false;
            //   }
          this.showAddress();
        }
      } catch (error) {
        console.log(error);
      }
    },
    showAddress: function() {
      // select country
       let addressArrCountry = this.addressArray[0].country;
       let selectCountry = this.countries.filter(function(element) {
         if (element._id === addressArrCountry) {
           return element;
         }
        });

        // select province
       let addressArrProvince = this.addressArray[0].province;
       let selectProvince = this.province.filter(function(element) {
         if (element._id === addressArrProvince) {
           return element;
         }
        });

        // select region
       let addressArrRegion = this.addressArray[0].region;
       let selectRegion = this.region.filter(function(element) {
         if (element._id === addressArrRegion) {
           return element;
         }
        });

        // select city
       let addressArrCity = this.addressArray[0].city;
       let selectCity = this.city.filter(function(element) {
         if (element._id === addressArrCity) {
           return element;
         }
        });
        // console.log("sss")
        // console.log(this.addressArray)

        if (this.addressArray[0].postal_code && this.addressArray[0].postal_code !== undefined) {
          this.addressArray[0].postal_code = this.addressArray[0].postal_code;
        } else {
          this.addressArray[0].postal_code = "";
        }
        this.newAddress = this.addressArray[0].address_line_1 + ", " + this.addressArray[0].address_line_2 + ", " + selectCity[0].city_name + ", " + selectRegion[0].region_name + ", " + selectProvince[0].province_name + ", " + selectCountry[0].country_name + ", " + this.addressArray[0].postal_code;

    }
  }
}
</script>
